import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";
import Icon from "../../../components/Icon";
import Dropdowncheckbox from "../../../components/Dropdowncheckbox";
import Checkbox from "../../../components/Checkbox";
import { useNavigate } from "react-router-dom";
import AccessControl from "../../../HOC/AccessControl";
const Panel = ({
  cameras,
  handleCapture,
  handleRecording,
  activeRecordings,
  onUpload,
  markAsPacked,
  currentOrder,
}) => {
  const [cameraOptions, setCameraOptions] = useState(() => {
    const savedOptions = localStorage.getItem("cameraOptions");
    if (savedOptions) {
      return JSON.parse(savedOptions);
    } else if (cameras.length === 1) {
      return {
        [cameras[0].deviceId]: { photo: true, video: true },
      };
    } else {
      return {};
    }
  });

  const [isVideoSelected, setIsVideoSelected] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (cameras.length === 1 && Object.keys(cameraOptions).length === 0) {
      setCameraOptions({
        [cameras[0].deviceId]: { photo: true, video: true },
      });
    }

    const hasVideoSelected = Object.values(cameraOptions).some(
      (options) => options?.video
    );
    setIsVideoSelected(hasVideoSelected);
    localStorage.setItem("cameraOptions", JSON.stringify(cameraOptions));
  }, [cameras, cameraOptions]);

   const handleOk = async () => {
    try {
      navigate("/packingslip", {
        state: {
          ids: [currentOrder?.id],
        },
      });
    } catch (error) {
      console.error("Error navigating:", error);
    }
  };
  return (
    <div className={cn("panel", styles.panel)}>
      <div className={styles.info}></div>
      <div className={styles.btns}>
        <div className="flex flex-col gap-4">
          <div className="flex flex-wrap gap-4">
            <div>
              <div>   
              <AccessControl
                  moduleCode="diMGeD"
                  requiredPrivilege="mrbNPj" >
                             
                <button
                  className={cn("button-stroke", styles.button)}
                  onClick={handleOk}
                >
                Print Packing Slip
                </button>
                </AccessControl> 
                <button
                  className={cn("button", styles.button)}
                  onClick={markAsPacked}
                >
                  Update Inventory
                </button>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Panel;
