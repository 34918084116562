import React from "react";
import styles from "./ProductsDashboard.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Overview from "./Overview";

import ProductViews from "./ProductViews";
import Products from "./Products";
import RoundGraph from "./RoundGraph";
const ProductsDashboard = () => {
  return (
    <>
      <div className={styles.section}>
        <Overview className={styles.card} />
        <div className={styles.row}>
          <div className={styles.col}>
            <Products />
          </div>
          <div className={styles.col}>
            <ProductViews />
            <div className={styles.container}>
              {" "}
              <RoundGraph />
            </div>
          </div>
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default ProductsDashboard;
