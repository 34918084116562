import Page from "../components/Page";
import AllOrders from "../screens/Orders/AllOrders/AllOrders";
// import Notification from "../screens/Notification";
import Settings from "../screens/Settings";
// import OrderDettailsIndex from "../screens/Orders/OrderDetails/OrderDettailsIndex";
import ScanningOrders from "../screens/ScanningOrders/Index";
// import GeneratePickListByOrderPrintView from "../screens/Orders/AllOrders/Filters/GeneratePickListByOrderPrintView";
// import GeneratePickListByProductPrintView from "../screens/Orders/AllOrders/Filters/GeneratePickListByProductPrintView";
// import AddStaffMember from "../screens/Staff/AddStaffMember/AddStaffMember";
import { MenuProvider } from "../context/Roles/MenuContext";
import Staffsindex from "../screens/Staff/Staffsindex";
import { FormikProvider } from "../screens/Settings/context/SettingsContext";

const routesConfig = [
  {
    path: "/orders",
    element: (
      <Page title="All Orders">
        <AllOrders />
      </Page>
    ),
    moduleCodes: ["diMGeD"],
    requiredPrivileges: ["91VsM3"],
  },

  {
    path: "/staff",
    element: (
      <Page title="Staff list">
        <Staffsindex />
      </Page>
    ),
    moduleCodes: ["eV79MY"],
    requiredPrivileges: ["xPGbZk"],
  },

  {
    path: "/settings",
    element: (
      <Page title="Settings">
        <MenuProvider>
          <FormikProvider>
            <Settings />
          </FormikProvider>
        </MenuProvider>
      </Page>
    ),
    moduleCodes: ["gsZHRE", "FvrVqN", "BjQPiy","k5WoWh","18q9ha","VBaqtS","2sxE9L"],
    requiredPrivileges: ["nnlrNJ", "nJLdKh", "OZnKi4","kI6pTK","4Yy3A1","HRkhhh","OFUpm7"]
  },

  {
    path: "/scan-orders",
    element: (
      <Page title="Scan Order">
        <ScanningOrders />
      </Page>
    ),
    moduleCodes: ["8FZN9w"],
    requiredPrivileges: ["a2YwMA"],
  },
];

export default routesConfig;
