import React from "react";
import { jsPDF } from "jspdf";
import styles from "./Row.module.sass";
import cn from "classnames";
import Icon from "../../../../../components/Icon";
import moment from "moment";
import Barcode from "react-barcode";
import html2canvas from "html2canvas";
import { useMenu } from "../../../../../context/Roles/MenuContext";
import ReactDOM from "react-dom";

const Row = ({ item, activeTable, activeId }) => {
  const { setSubActiveTab, setTagBarcode } = useMenu();

  const handleUpdate = (barcode_value) => {
    setSubActiveTab(3);
    setTagBarcode(barcode_value);
  };

  const handleDownload = async (event, barcode_value) => {
    event.stopPropagation();
    //  hidden div to hold the barcode
    const hiddenDiv = document.createElement("div");
    hiddenDiv.style.position = "absolute";
    hiddenDiv.style.top = "-10000px";
    document.body.appendChild(hiddenDiv);

    // Rendering the barcode into the hidden div
    const barcodeElement = document.createElement("div");
    hiddenDiv.appendChild(barcodeElement);

    const barcode = <Barcode value={barcode_value} />;
    ReactDOM.render(barcode, barcodeElement);

    // Wait for the Barcode component to render
    await new Promise((resolve) => setTimeout(resolve, 100));

    // Capture the barcode as an image using html2canvas
    const canvas = await html2canvas(barcodeElement);
    const barcodeImage = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    pdf.addImage(barcodeImage, "PNG", 30, 50, 150, 70);
    pdf.save(`barcode_${barcode_value}.pdf`);

    document.body.removeChild(hiddenDiv);
  };

  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item.id },
          { [styles.active]: activeTable }
        )}
        onClick={() => handleUpdate(item)}
      >
        <div className={styles.col}>
          <div className={styles.email}>{item.barcode}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.email}>{item.tag_name}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.email}>
            {moment(item.created_at).format("DD MMM YYYY")}
          </div>
        </div>
        <div className={styles.col}>
          <div onClick={(event) => handleDownload(event, item.barcode)}>
            <Icon name="download" size="24" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Row;
