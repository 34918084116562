import { progress } from "../utils";

export const market = [
  {
    id: 0,
    product: "Roshit",
    category: "Packer",
    image: "/images/content/product-pic-1.jpg",
    image2x: "/images/content/product-pic-1@2x.jpg",
    status: true,
    price: "0.4%",
    sales: 70,
    balance: 37.8,
    views: 8,
    viewsPercent: progress(),
    likesPercent: progress(),
    likes: 11,
  },
  {
    id: 1,
    product: "Nishant",
    category: "Picker",
    image: "/images/content/product-pic-2.jpg",
    image2x: "/images/content/product-pic-2@2x.jpg",
    status: false,
    price: "2%",
    sales: 65,
    balance: 27.5,
    views: 5,
    viewsPercent: progress(),
    likesPercent: progress(),
    likes: 17,
  },
  {
    id: 2,
    product: "Keerthi",
    category: "Picker",
    image: "/images/content/product-pic-3.jpg",
    image2x: "/images/content/product-pic-3@2x.jpg",
    status: true,
    price: "2.4%",
    sales: 39,
    balance: 18.3,
    views: 4,
    viewsPercent: progress(),
    likesPercent: progress(),
    likes: 30,
  },
  {
    id: 3,
    product: "Saurabh",
    category: "Picker",
    image: "/images/content/product-pic-4.jpg",
    image2x: "/images/content/product-pic-4@2x.jpg",
    status: false,
    price: "0.2%",
    sales: 90,
    balance: 60.5,
    views: 13,
    viewsPercent: progress(),
    likesPercent: progress(),
    likes: -8.2,
  },
  {
    id: 4,
    product: "Nikhil",
    category: "Admin",
    image: "/images/content/product-pic-5.jpg",
    image2x: "/images/content/product-pic-5@2x.jpg",
    status: true,
    price: "0.9%",
    sales: 46,
    balance: 27.5,
    views: 3,
    viewsPercent: progress(),
    likesPercent: progress(),
    likes: 23,
  },
  {
    id: 5,
    product: "Satish",
    category: "Admin",
    image: "/images/content/product-pic-6.jpg",
    image2x: "/images/content/product-pic-6@2x.jpg",
    status: true,
    price: "0.3%",
    sales: 28,
    balance: -2.8,
    views: 15,
    viewsPercent: progress(),
    likesPercent: progress(),
    likes: 3,
  },
  {
    id: 6,
    product: "Saurav",
    category: "Packer",
    image: "/images/content/product-pic-1.jpg",
    image2x: "/images/content/product-pic-1@2x.jpg",
    status: false,
    price: "3.4%",
    sales: 88,
    balance: -2.5,
    views: 12,
    viewsPercent: progress(),
    likesPercent: progress(),
    likes: 17,
  },
  {
    id: 7,
    product: "Somya",
    category: "Picker",
    image: "/images/content/product-pic-2.jpg",
    image2x: "/images/content/product-pic-2@2x.jpg",
    status: true,
    price: "0.7%",
    sales: "2.4%",
    balance: -4.8,
    views: 23,
    viewsPercent: progress(),
    likesPercent: progress(),
    likes: 40,
  },

];

export const released = [
  {
    id: 0,
    product: "Bento Matte 3D Illustration",
    category: "UI design kit",
    image: "/images/content/product-pic-1.jpg",
    image2x: "/images/content/product-pic-1@2x.jpg",
    price: 98,
    status: true,
    ratingValue: 4.8,
    ratingCounter: 87,
    sales: 3200,
    balance: 55.8,
    views: 47850,
    viewsPercent: progress(),
  },
  {
    id: 1,
    product: "DSM - Geometry pattern",
    category: "UI design kit",
    image: "/images/content/product-pic-2.jpg",
    image2x: "/images/content/product-pic-2@2x.jpg",
    price: 123,
    status: false,
    ratingValue: 4.6,
    ratingCounter: 127,
    sales: 4900,
    balance: 35.8,
    views: 32500,
    viewsPercent: progress(),
  },
  {
    id: 2,
    product: "Node - Crypto iOS UI design kit",
    category: "UI design kit",
    image: "/images/content/product-pic-3.jpg",
    image2x: "/images/content/product-pic-3@2x.jpg",
    price: 0,
    status: true,
    sales: 5600,
    balance: -15.8,
    views: 55500,
    viewsPercent: progress(),
  },
  {
    id: 3,
    product: "TaskEz: Productivity App iOS UI Kit",
    category: "UI design kit",
    image: "/images/content/product-pic-4.jpg",
    image2x: "/images/content/product-pic-4@2x.jpg",
    price: 33,
    status: false,
    ratingValue: 4.9,
    ratingCounter: 7,
    sales: 1100,
    balance: 19.2,
    views: 39500,
    viewsPercent: progress(),
  },
  {
    id: 4,
    product: "Academe 3D Education Icons",
    category: "UI design kit",
    image: "/images/content/product-pic-5.jpg",
    image2x: "/images/content/product-pic-5@2x.jpg",
    price: 45,
    status: true,
    ratingValue: 4.8,
    ratingCounter: 87,
    sales: 3200,
    balance: 55.8,
    views: 47850,
    viewsPercent: progress(),
  },
  {
    id: 5,
    product: "Academe 3D Education Icons",
    category: "UI design kit",
    image: "/images/content/product-pic-5.jpg",
    image2x: "/images/content/product-pic-5@2x.jpg",
    price: 45,
    status: true,
    ratingValue: 4.8,
    ratingCounter: 87,
    sales: 3200,
    balance: 55.8,
    views: 47850,
    viewsPercent: progress(),
  },
  {
    id: 6,
    product: "Academe 3D Education Icons",
    category: "UI design kit",
    image: "/images/content/product-pic-5.jpg",
    image2x: "/images/content/product-pic-5@2x.jpg",
    price: 45,
    status: true,
    ratingValue: 4.8,
    ratingCounter: 87,
    sales: 3200,
    balance: 55.8,
    views: 47850,
    viewsPercent: progress(),
  },
];
