import React, { useState } from "react";
import cn from "classnames";
import styles from "./File.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";

const File = ({ className, label, tooltip, title, form, field }) => {

  const [image, setImage] = useState(null);
  const [dragging, setDragging] = useState(false);

  // Handle file input change
  const handleFileChange = (file) => {
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setImage(URL.createObjectURL(file));
      form.setFieldValue(field.name, file);
    }
  };

  // Remove the selected image
  const handleRemoveImage = () => {
    setImage(null);
    form.setFieldValue(field.name, null);
  };

  // Handle drag events
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files[0];
    handleFileChange(file);
  };

  // Handle file input change from the click-to-upload feature
  const handleInputChange = (e) => {
    const file = e.target.files[0];
    handleFileChange(file);
  };

  return (
    <div
      className={cn(styles.file, className, { [styles.dragging]: dragging })}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {label && (
        <div className={styles.label}>
          {label}{" "}
          {tooltip && <Tooltip className={styles.tooltip} title={tooltip} />}
        </div>
      )}
      <div className={styles.wrap}>
        {image ? (
          <div className={styles.preview}>
            <img src={image} alt="Preview" className={styles.imagePreview} />
            <button className={styles.removeButton} onClick={handleRemoveImage}>
              <Icon name="trash" size="24" />
            </button>
          </div>
        ) : (
          <>
            <input
              className={styles.input}
              type="file"
              accept="image/jpeg, image/png"
              onChange={handleInputChange}
            />
            <div className={styles.box}>
              <Icon name="upload" size="24" />
              {title}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default File;
