import React, { useEffect, useState } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import Checkbox from "../../../components/Checkbox";
import Loader from "../../../components/Loader";
import custome_axios from "../../../axios";
import Row from "./Row";
import { customers } from "../../../mocks/customers";
import { Pagination } from "antd";
import { GET_ALL_EMPLOYEES_STAFF_MODULE } from "../../../API/Employee";

const Table = ({ className, activeTable, setActiveTable, onEditClick,searchKeywords  }) => {
  const [chooseAll, setСhooseAll] = useState(false);
  const [activeId, setActiveId] = useState(customers[0].id);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  
  useEffect(() => {
    const fetchStaffData = async () => {
      setLoading(true);
      try {
        const { data, count } = await GET_ALL_EMPLOYEES_STAFF_MODULE({
          page,
          pageSize: itemsPerPage,
          keywords: searchKeywords
        });
        setStaffData(data);
        setTotalItems(count);
      } catch (error) {
        console.error("Error fetching staff data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchStaffData();
  }, [page, itemsPerPage, searchKeywords]);

  const handleItemsPerPageChange = (current, size) => {
    setItemsPerPage(size);
    setPage(1);
  };

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={cn(styles.table)}>
        <div className={cn(styles.row, { [styles.active]: activeTable })}>
          <div className={styles.col}>
          </div>
          <div className={styles.col}>Name</div>
          <div className={styles.col}>Email</div>
          <div className={styles.col}>Role</div>
          <div className={styles.col}>Status</div>
          <div className={styles.col}>Orders Assigned</div>
          <div className={styles.col}>Orders Picked</div>
        </div>
        {loading ? (
          <div className={styles.loaderContainer}>
            <Loader className={cn(styles.centeredLoader, styles.loader)} />
          </div>
        ) : (
          staffData.map((x, index) => (
            <Row
              item={x}
              key={index}
              activeTable={activeTable}
              setActiveTable={setActiveTable}
              activeId={activeId}
              setActiveId={setActiveId}
              value={selectedFilters.includes(x.id)}
              onChange={() => handleChange(x.id)}
              onEditClick={onEditClick}
            />
          ))
        )}
      </div>
      <div className={styles.foot}>
        <Pagination
          current={page}
          total={totalItems}
          pageSize={itemsPerPage}
          onChange={(currentPage) => setPage(currentPage)}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          showSizeChanger
          onShowSizeChange={handleItemsPerPageChange}
        />
      </div>
    </div>
  );
};

export default Table;
