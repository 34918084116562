import React, { useCallback, useEffect, useState } from "react";
import styles from "./OrdersTable.module.sass";
import Checkbox from "../../../../components/Checkbox";
import Row from "./Row";
import { fetchOrders } from "../../../../API/Orders";
import { BiDownArrowAlt, BiUpArrowAlt } from "react-icons/bi";
import { ConfigProvider, Pagination } from "antd";
import useDarkMode from "use-dark-mode";
import CustomToast from "../../../../components/CustomToast";
import Card from "../../../../components/Card";
import cn from "classnames";
import SearchedOrdersTable from "./SearchedOrdersTable";
const OrdersTable = ({
  search,
  setSearch,
  risk,
  tags,
  notTaggedWith,
  deliveryStatus,
  deliveryMethod,
  financial,
  appName,
  returnStatus,
  channelName,
  fulfillmentStatus,
  shopifyOrderStatus,
  valueFilterByDate,
  valueFilterByToDate,
  valueFilterByFromDate,
  checkedItems,
  selectedEmployee,
  handleCheckboxChange,
  page,
  setPage,
  totalItems,
  setTotalItems,
  itemsPerPage,
  setItemsPerPage,
  customFilters,
  customFieldTrigger,
  resetCheckedItems,
  duration,
  returnedOrders,
}) => {
  const [chooseAll, setСhooseAll] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [data, setData] = useState([]);
  const [searchedOrders, setSearchedOrders] = useState([]);
  const [searchedOrderNumbers, setSearchedOrderNumbers] = useState(new Set());
  const [sortField, setSortField] = useState("order_number");
  const [sortOrder, setSortOrder] = useState("desc");
  const [indeterminate, setIndeterminate] = useState(false);
  const [loading, setLoading] = useState(false);
  const fetchOrderBySearch = async (searchTerm) => {
    try {
      const response = await fetchOrders(
        [
          { key: "orderNumber", value: searchTerm },
          { key: "duration", value: duration },
        ],
        {}
      );

      if (response.data && response.data.length > 0) {
        const timestamp = Date.now();
        const searchedOrdersWithTimestamp = response.data.map((order) => ({
          ...order,
          searchTimestamp: timestamp,
        }));

        setSearchedOrders((prevOrders) => {
          const newOrders = [...prevOrders];
          searchedOrdersWithTimestamp.forEach((newOrder) => {
            const existingIndex = newOrders.findIndex(
              (o) => o.id === newOrder.id
            );
            if (existingIndex >= 0) {
              newOrders[existingIndex] = newOrder;
            } else {
              newOrders.push(newOrder);
            }
          });
          return newOrders;
        });

        setSearchedOrderNumbers((prevSet) => new Set(prevSet).add(searchTerm));

        const newCheckedItems = { ...checkedItems };
        searchedOrdersWithTimestamp.forEach((order) => {
          newCheckedItems[order.id] = true;
        });
        handleCheckboxChange(null, newCheckedItems);
      }
    } catch (error) {
      console.error("Error fetching order by search:", error);
    }
  };
  const handleClearSearchedOrders = useCallback(() => {
    setSearchedOrders([]);
    setSearchedOrderNumbers(new Set());
    // Clear checked items for searched orders
    const newCheckedItems = { ...checkedItems };
    searchedOrders.forEach(order => {
      delete newCheckedItems[order.id];
    });
    handleCheckboxChange(null, newCheckedItems);
  }, [searchedOrders, checkedItems, handleCheckboxChange]);
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const queryParams = [
        { key: "financialStatus", value: financial },
        { key: "fulfillmentStatus", value: fulfillmentStatus },
        { key: "sort_by", value: sortField },
        { key: "sort_order", value: sortOrder },
        { key: "fromDate", value: valueFilterByFromDate },
        { key: "toDate", value: valueFilterByToDate },
        { key: "page", value: page },
        { key: "pageSize", value: itemsPerPage },
        { key: "assignTo", value: selectedEmployee },
        { key: "customFilterId", value: customFilters },
        { key: "riskLevel", value: risk },
        { key: "returnStatus", value: returnStatus },
        { key: "deliveryStatus", value: deliveryStatus },
        { key: "deliveryMethod", value: deliveryMethod },
        { key: "channel", value: channelName },
        { key: "app", value: appName },
        { key: "shopifyOrderStatus", value: shopifyOrderStatus },
        { key: "duration", value: duration },
        { key: "returnedOrders", value: returnedOrders },
      ];

      const payload = {
        taggedWith: Array.isArray(tags) ? tags.join(",") : tags || "",
        notTaggedWith: Array.isArray(notTaggedWith)
          ? notTaggedWith.join(",")
          : notTaggedWith || "",
      };

      const response = await fetchOrders(queryParams, payload);
      setData(response.data);
      setTotalItems(response.count);
      const newCheckedItems = { ...checkedItems };
      searchedOrders.forEach(order => {
        if (checkedItems[order.id]) {
          newCheckedItems[order.id] = true;
        }
      });
      handleCheckboxChange(null, newCheckedItems);
    } catch (error) {
      CustomToast.fire({
        icon: "error",
        text: "Error fetching data:",
      });
    } finally {
      setLoading(false);
    }
  }, [
    financial,
    fulfillmentStatus,
    tags,
    notTaggedWith,
    sortField,
    sortOrder,
    valueFilterByFromDate,
    valueFilterByToDate,
    page,
    itemsPerPage,
    selectedEmployee,
    customFilters,
    returnStatus,
    shopifyOrderStatus,
    risk,
    deliveryStatus,
    deliveryMethod,
    appName,
    channelName,
    duration,
    search,
    returnedOrders,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData, customFieldTrigger]);

  useEffect(() => {
    if (search) {
      const searchTerm = search;
      if (!searchedOrderNumbers.has(searchTerm)) {
        fetchOrderBySearch(searchTerm);
      }
    }
  }, [search]);

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  };

  useEffect(() => {
    const checkedCount = data.filter((item) => checkedItems[item.id]).length;
    if (checkedCount === 0) {
      setSelectAll(false);
    } else if (checkedCount === data.length) {
      setSelectAll(true);
    }
    setIndeterminate(checkedCount > 0 && checkedCount < data.length);
  }, [checkedItems, data]);

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    const newCheckedItems = {};
    data.forEach((item) => {
      newCheckedItems[item.id] = checked;
    });
    handleCheckboxChange(e, newCheckedItems);
    setSelectAll(checked);
    setIndeterminate(false);
  };

  const handlePageChange = (currentPage, pageSize) => {
    setPage(currentPage);
    if (pageSize !== itemsPerPage) {
      setItemsPerPage(pageSize);
    }
  };

  const darkMode = useDarkMode(false);
  const themeConfig = {
    token: {
      colorPrimary: "#1890ff",
      colorTextBase: darkMode.value ? "#ffffff" : "#000000",
      colorBgContainer: darkMode.value ? "#1f1f1f" : "#ffffff",
      colorBgElevated: darkMode.value ? "#2f2f2f" : "#ffffff",
      colorText: darkMode.value ? "#ffffff" : "#000000",
      buttonBoxShadow: darkMode.value ? "none" : "0 2px 0 rgb(230, 247, 255)",
      paginationBg: darkMode.value ? "#2f2f2f" : "#ffffff",
      paginationItemBg: darkMode.value ? "#2f2f2f" : "#ffffff",
      paginationItemBgHover: darkMode.value ? "#3a3a3a" : "#f5f5f5",
      paginationItemActiveBg: darkMode.value ? "#3a3a3a" : "#e6f7ff",
      paginationItemLinkBg: darkMode.value ? "#2f2f2f" : "#ffffff",
    },
  };

  return (
    <>
      <ConfigProvider theme={themeConfig}>
        <div className={styles.market}>
          <>
            {searchedOrders.length > 0 && (
              <div class="my-5">
                <Card className={styles.card}>
                  <SearchedOrdersTable
                    orders={searchedOrders}
                    checkedItems={checkedItems}
                    handleCheckboxChange={handleCheckboxChange}
                    selectAll={selectAll}
                    handleSelectAll={handleSelectAll}
                    indeterminate={indeterminate}
                    onClearSelection={handleClearSearchedOrders}
                  />
                </Card>
              </div>
            )}
          </>
          <Card className={styles.card}>
            <div className="overflow-auto">
              <div className={styles.table}>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <Checkbox
                      className={styles.checkbox}
                      checked={selectAll}
                      onChange={handleSelectAll}
                      ref={(input) => {
                        if (input) {
                          input.indeterminate = indeterminate;
                        }
                      }}
                    />
                  </div>
                  {data[0] &&
                    Object.keys(data[0]).map((item, index) => (
                      <div
                        className={cn(
                          "whitespace-nowrap flex items-center cursor-pointer",
                          styles.col
                        )}
                        key={index}
                        onClick={() => handleSort(item)}
                      >
                        {item
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}

                        <span>
                          {sortField === item ? (
                            sortOrder === "asc" ? (
                              <BiUpArrowAlt
                                size="20px"
                                className="inline-block"
                              />
                            ) : (
                              <BiDownArrowAlt
                                size="20px"
                                className="inline-block"
                              />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    ))}
                </div>

                {data.map((item, index) => (
                  <Row
                    key={index}
                    data={item}
                    checkedItems={checkedItems}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                ))}
              </div>
            </div>

            <div className={styles.foot}>
              <Pagination
                current={page}
                total={totalItems}
                pageSize={itemsPerPage}
                onChange={handlePageChange}
                onShowSizeChange={(current, size) => {
                  setItemsPerPage(size);
                  setPage(1);
                  setSelectAll(false);
                }}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} orders`
                }
                showSizeChanger
              />
            </div>
          </Card>
        </div>
      </ConfigProvider>
    </>
  );
};

export default OrdersTable;
