import React, { useEffect, useState } from "react";
import styles from "./UpdateInventory.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";
import Dropdown from "../../../components/Dropdown";
import Panel from "./Panel/index";
import Modal from "../../../components/Modal";
import InventoryMode from "../InventoryMode";
import InventoryTable from "./InventoryTable/index";
import {
  GET_LOCATIONS,
  GET_SINGLE_VARIANT,
  UPDATE_INVENTORY,
} from "../../../API/Inventory";
import CustomToast from "../../../components/CustomToast";
import Swal from "sweetalert2";
import useDarkMode from "use-dark-mode";
import Loader from "../../../components/Loader";

const UpdateInventory = () => {
  const [locations, setLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("Select a location");
  const [variants, setVariants] = useState([]);
  const [scannedSku, setScannedSku] = useState("");
  const [mode, setMode] = useState("increment");
  const [value, setValue] = useState(1);
  const [isUpdateInventoryModalVisible, setIsUpdateInventoryModalVisible] =
    useState(false);
  const [productCount, setProductCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [notes, setNotes] = useState("");
  const [loading, setLoading] = useState(false);
  const darkMode = useDarkMode(false);
  const location = useLocation();

  const buttonClass = (isActive) => `
    ml-[5px] px-[15px] py-[5px] rounded-lg border-gray-300
    ${isActive ? "bg-white text-[black]" : "bg-[#F4F4F4] text-black font-bold"}
  `;
  const darkbuttonClass = (isActive) => `
  ml-[5px] px-[15px] py-[5px] rounded-lg border-gray-300
  ${
    isActive
      ? "bg-[#F4F4F4] text-[black]"
      : "bg-[#1A1D1F] text-[white] font-bold"
  }
`;
  const getButtonClass = (isActive) =>
    darkMode.value ? darkbuttonClass(isActive) : buttonClass(isActive);
  const showToast = CustomToast();

  useEffect(() => {
    fetchLocations();
  }, []);
  const handleStatusClick = () => {
    setIsUpdateInventoryModalVisible(true);
    handleClearAll();
  };
  function formatMode(mode) {
    if (!mode) return ""; // Handle null or undefined input
    return mode.replace(/([a-z])([A-Z])/g, "$1 $2");
  }

  const queryParams = new URLSearchParams(location.search);
  const selectedMode = queryParams.get("mode");
  const formattedMode = formatMode(selectedMode);
  useEffect(() => {
    setProductCount(variants.length);
    const newTotalPrice = variants.reduce((sum, variant) => {
      return sum + variant.price * Math.abs(variant.updateBy);
    }, 0);
    setTotalPrice(newTotalPrice);
  }, [variants]);

  const fetchLocations = async () => {
    setLoading(true);
    try {
      const data = await GET_LOCATIONS();
      setLocations(data);
    } catch (error) {
      console.error("Error fetching locations:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLocationChange = (locationName) => {
    handleClearAll();
    if (locationName === "Select a location") {
      setSelectedLocation("Select a location");
      setSelectedLocationId("");
    } else {
      const location = locations.find((loc) => loc.name === locationName);
      if (location) {
        setSelectedLocation(location.name);
        setSelectedLocationId(location.location_id);
      }
    }
  };

  const handleSkuScan = async (event) => {
    event.preventDefault();

    if (!selectedLocationId || !scannedSku) {
      Swal.fire({
        icon: "warning",
        title: "Incomplete Information",
        text: "Please select a location and enter a SKU",
      });
      return;
    }

    try {
      const response = await GET_SINGLE_VARIANT(scannedSku, selectedLocationId);

      if (response.status === 200) {
        const newVariant = response.data.data.ProductVariant;
        showToast(`Fetched Successfully`, "success");

        setVariants((prevVariants) => {
          const existingVariantIndex = prevVariants.findIndex(
            (v) =>
              v.sku === newVariant.sku && v.location_id === selectedLocationId
          );

          if (existingVariantIndex > -1) {
            return prevVariants.map((variant, index) =>
              index === existingVariantIndex
                ? {
                    ...variant,
                    VariantInventory: newVariant.VariantInventory,
                    updateBy: calculateUpdateBy(variant.updateBy),
                  }
                : variant
            );
          } else {
            return [
              ...prevVariants,
              {
                ...newVariant,
                updateBy: calculateUpdateBy(0),
                inventory_item_id:
                  newVariant.VariantInventory.inventory_item_id,
                variant_id: newVariant.VariantInventory.variant_id,
                location_id: selectedLocationId,
              },
            ];
          }
        });

        setScannedSku("");
      } else {
        showToast(`Failed to fetch variant data`, "error");
      }
    } catch (error) {
      console.error("Error fetching variant:", error);
      showToast(`An error occurred while fetching variant data`, "error");
    }
  };
  const calculateUpdateBy = (previousUpdateBy) => {
    if (mode === "increment") return previousUpdateBy + 1;
    if (mode === "decrement") return previousUpdateBy - 1;
    return previousUpdateBy + parseInt(value) || 0;
  };

  const handleSetBy = (newValue) => {
    const parsedValue = parseInt(newValue);
    if (!isNaN(parsedValue) && parsedValue !== 0) {
      setMode("set");
      setValue(parsedValue);
    } else if (newValue === "-") {
      setMode("set");
      setValue(newValue);
    } else {
      setMode("set");
      setValue("");
    }
  };

  const handleModeChange = (newMode) => {
    setMode(newMode);
    if (newMode !== "set") {
      setValue(1);
    }
  };
  const handleUpdateInventory = async () => {
    if (!selectedLocationId) {
      showToast(
        `Please select a location before updating inventory.`,
        "warning"
      );
      return;
    }

    const hasChanges = variants.some((variant) => variant.updateBy !== 0);
    if (!hasChanges) {
      showToast(`No inventory changes have been made.`, "warning");
      return;
    }

    setLoading(true);

    const inventories = variants
      .filter((variant) => variant.updateBy !== 0)
      .map((variant) => {
        const baseInventory = {
          inventory_item_id: variant.VariantInventory.inventory_item_id,
          variant_id: variant.VariantInventory.variant_id,
        };

        if (selectedMode === "OnHand") {
          return {
            ...baseInventory,
            quantity: variant.VariantInventory.on_hand + variant.updateBy,
          };
        } else if (selectedMode === "Available") {
          return {
            ...baseInventory,
            quantity: variant.updateBy,
          };
        }
      });

    const modeMapping = {
      OnHand: "on_hand",
      Available: "available",
    };
    const mode = modeMapping[selectedMode] || "";
    const payload = {
      type: mode,
      location_id: selectedLocationId,
      notes: notes,
      inventories: inventories,
    };

    try {
      const response = await UPDATE_INVENTORY(payload);

      if (response.status === 200) {
        showToast(`Inventory updated successfully!`, "success");
        handleClearAll();
      } else if (
        response.status === 500 &&
        response.data.err === "Please provide valid type"
      ) {
        showToast(
          `${"Please provide a valid type for inventory update."} `,
          "error"
        );
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      console.error("Error updating inventory:", error);
      showToast(
        `${error.message || "Error updating inventory. Please try again."} `,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClearAll = () => {
    setVariants([]);
    setNotes("");
    setScannedSku("");
    setMode("increment");
    setValue(1);
  };

  return (
    <>
      {loading ?(<Loader className={styles.loader} />):(
      <div>
      <Card>
        <div className="flex mb-10">
          <div className="mr-5 mt-2.5">Location </div>
          <div className="mb-4 h-5">
            <Dropdown
              className={cn("custom-dropdown", styles.customdropdown)}
              classDropdownHead="custom-dropdown-head"
              classDropdownLabel="custom-dropdown-label"
              value={selectedLocation}
              setValue={handleLocationChange}
              options={[
                "Select a location",
                ...locations.map((location) => location.name),
              ]}
            />
          </div>
        </div>

        <div className="flex justify-between mb-[2%] w-[100%] sm:flex-col md:flex-col lg:flex-row xl:flex-col 2xl:flex-row md:mb-[10px]">
          <div className="flex w-[75%] md:w-[100%]">
            <div className="flex md:flex-col sm:flex-col">
              <div className="mt-3 mr-3">Update Mode</div>
              <div
                className={cn(
                  "flex items-center gap-[5px] bg-[#F4F4F4] rounded-lg",
                  styles.update
                )}
              >
                <button
                  className={getButtonClass(mode === "increment")}
                  onClick={() => handleModeChange("increment")}
                >
                  Increment
                </button>
                <button
                  className={getButtonClass(mode === "decrement")}
                  onClick={() => handleModeChange("decrement")}
                >
                  Decrement
                </button>
                <div
                  className={cn(
                    "flex items-center border border-gray-300 rounded-lg overflow-hidden",
                    styles.mainSet
                  )}
                >
                  <span
                    className={cn(
                      "px-2 py-2 bg-[#FFFFFF] text-gray-600",
                      styles.set
                    )}
                  >
                    Set by
                  </span>
                  <input
                    type="number"
                    value={mode === "set" ? value : ""}
                    onChange={(e) => handleSetBy(e.target.value)}
                    min="-999999"
                    max="999999"
                    step="1"
                    className={cn(
                      "w-12 px-2 py-2 text-center focus:outline-none",
                      styles.setnumber
                    )}
                    placeholder="1"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-2 sm:mt-4 md:mt-4">
            <Form
              className={cn(styles.form)}
              value={scannedSku}
              setValue={setScannedSku}
              onSubmit={handleSkuScan}
              placeholder="Scan product with SKU"
              type="text"
              name="search"
              icon="search"
              autoFocus={true}
            />
            <Link
              className={cn("button", styles.button)}
              onClick={handleSkuScan}
            >
              <Icon name="add" size="24" />
              <span>Add</span>
            </Link>
          </div>
        </div>

        <div className="flex justify-between" style={{ marginTop: "5%" }}>
          <div
            className={cn("title-red", styles.subtitle)}
            style={{ letterSpacing: "0" }}
          >
            <div style={{ display: "flex" }}>
              <div className={styles.heade}>
                <div>
                  Updated :<span className={styles.headespace}></span>
                </div>
                <div className={styles.divider}></div>
              </div>
              <div className={styles.heade}>
                <div>
                  Products :
                  <span className={styles.headespace}>
                    {" "}
                    {productCount || "0"}
                  </span>
                </div>
                <div className={styles.divider}></div>
              </div>
              <div className={styles.heade}>
                <div>
                  Items :
                  <span className={styles.headespace}>
                    {productCount || "0"}
                  </span>
                </div>
                <div className={styles.divider}></div>
              </div>
              <div className={styles.heade}>
                <div>
                  Price :
                  <span className={styles.headespace}>
                    {totalPrice ? totalPrice.toFixed(2) : "0.00"}
                  </span>
                </div>
                <div className={styles.divider}></div>
              </div>
            </div>
          </div>

          <div
            className={cn(
              "status-yellow ml-[7px] cursor-pointer",
              styles.status
            )}
            onClick={handleStatusClick}
          >
            {formattedMode} Mode
          </div>
        </div>
      </Card>
      <div className="mt-5 mb-[150px]">
        <Card>
          <InventoryTable
            variants={variants}
            handleUpdateInventory={handleUpdateInventory}
            mode={mode}
            value={value}
          />
        </Card>
      </div>
      <div className="fixed bottom-7 left-30 right-5">
        <Panel
          notes={notes}
          setNotes={setNotes}
          onClearAll={handleClearAll}
          onUpdateInventory={handleUpdateInventory}
        />
      </div>

      <Modal
        visible={isUpdateInventoryModalVisible}
        onClose={() => setIsUpdateInventoryModalVisible(false)}
      >
        <InventoryMode
          onClose={() => setIsUpdateInventoryModalVisible(false)}
        />
      </Modal>
      </div>
      )}
    </>
  );
};

export default UpdateInventory;
