import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useLocation } from "react-router-dom";
import moment from "moment";
import cn from "classnames";
import { GENERATE_PICKLIST_BY_ORDER } from "../../../../API/Orders";
import styles from "./GeneratePickListByOrderPrintView.module.sass";
import Barcode from "react-barcode";
import Tag from "../../../../assets/images/tag.svg";
import CustomToast from "../../../../components/CustomToast";
import { addPrintTags } from "../../../../API/Orders";
import Loader from "../../../../components/Loader";
const GeneratePickListByOrderPrintView = () => {
  const showToast = CustomToast();
  const [data, setData] = useState([]);
  const [orders, setOrders] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const printComponentRef = useRef();
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const ids = searchParams.get("ids");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const checkedItems = location.state?.checkedItems || {};
  const itemIds = Object.keys(checkedItems);

  const handleAddPrintTag = async () => {
    try {
      // const order_id_list = itemIds.split(",").map(Number);
      const order_id_list = itemIds.map(Number);
      const res = await addPrintTags(order_id_list);
      if (res.status === 200) {
        showToast(res.data.message, "success");
      }
    } catch (err) {
      console.error("err", err);
      showToast("Failed to add tags", "error");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await GENERATE_PICKLIST_BY_ORDER(itemIds);
        setData(response.data || []);
        setOrders(response.data.orders || []);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    // Hide the text element within the barcode
    const barcodes = document.querySelectorAll(".barcode");
    barcodes.forEach((barcode) => {
      const texts = barcode.querySelectorAll("text");
      texts.forEach((text) => (text.style.display = "none"));
    });
  }, [data]);

  const formatDateTime = (date) => {
    return moment(date).format("MM-DD-YYYY, hh:mm:ss A");
  };
  const formatName = (name) => {
    if (!name) return "";
    return name.split("_")[0].charAt(0).toUpperCase() + name.slice(1);
  };

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    documentTitle: "Picklist",
    pageStyle: `
    @page {
      size: A4  ;
      margin: 0 !important; /* Force margin to 0 */
    }
    @media print {
      body {
        margin: 0 !important; /* Remove body margin */
        padding: 0 !important; /* Remove padding */
      }
      * {
        box-sizing: border-box; /* Ensure consistent box sizing */
      }
    }
  `,
    onAfterPrint: () => {
      console.log("Doc Downloaded");
    },
  });


  const findProductImage = (item) => {
    if (item.variant && item.variant.image_src) {
      return item.variant.image_src;
    } else {
      if(item.product?.ProductImage){
        return item.product.ProductImage[0]?.src;
      }
      return null;
    }
  };
 
  return (
    <div className={styles.scrollable_content}>
      <div className="flex flex-col items-center justify-center">
        <div className="flex  items-center justify-center">
          <button
            className={cn(
              "button",
              styles.button,
              "bg-blue-500 hover:bg-blue-700 text-white font-bold my-4"
            )}
            onClick={handleAddPrintTag}
          >
            Add PickList Tag
          </button>
          <button
            className={cn(
              "button",
              styles.button,
              "bg-blue-500 hover:bg-blue-700 text-white font-bold my-4 ml-3"
            )}
            onClick={handlePrint}
          >
            Print
          </button>
        </div>
        <div className="w-[80%] overflow-y-auto" >
        {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <Loader className="w-12 h-12" />
            </div>
          ) : (

          <div  ref={printComponentRef}>
            {orders.map((order) => (
              <div className={styles.page_break}>
                <div
                  key={order.id}
                  className="border border-gray-300 rounded-md mb-4 p-4 "
                >
                  <div className="flex justify-center mb-4">
                    <img
                      className={cn("w-42 h-22", styles.store_logo)}
                      src={data.store.store_logo}
                      alt="Store Logo"
                    />
                  </div>

                  <div className="flex justify-between mb-2">
                    <div className="sm-full w:w-1/2 p-4">
                      <div className={styles.detail_wrapper}>
                        <p>
                          <strong>Printed On:</strong>
                        </p>
                        <p className={styles.subtext}>
                          {formatDateTime(currentDateTime)}
                        </p>
                      </div>
                      <div className={styles.detail_wrapper}>
                        <p>
                          <strong>Delivery/Courier Charges</strong>
                          <span className="text-[15px] -mt-[50px]">
                            ({order.delivery_method?order.delivery_method:"-"})
                            <strong> </strong>
                          </span>
                        </p>
                        <p className={styles.subtext}>
                          {" "}
                          {order.ShippingLine[0]?.price
                            ? order.ShippingLine[0]?.price
                            : "NA"}
                        </p>
                      </div>
                      <div className={styles.detail_wrapper}>
                        <p>
                          <strong>Shipping Address:</strong>
                        </p>
                        <p className={styles.subtext}>
                          {order.ShippingAddress[0]?.first_name}{" "}
                          {order.ShippingAddress[0]?.last_name}
                        </p>

                        <p className={styles.subtext}>
                          {order.ShippingAddress[0]?.address1
                            ? `${order.ShippingAddress[0]?.address1},`
                            : null}
                        </p>
                        <p className={styles.subtext}>
                          {order.ShippingAddress[0]?.address2
                            ? `${order.ShippingAddress[0]?.address2},`
                            : null}
                        </p>
                        <p className={styles.subtext}>
                          {order.ShippingAddress[0]?.zip
                            ? `${order.ShippingAddress[0]?.zip},`
                            : null}
                        </p>
                        <p className={styles.subtext}>
                          {order.ShippingAddress[0]?.city
                            ? `${order.ShippingAddress[0]?.city},`
                            : ""}
                          {order.ShippingAddress[0]?.province
                            ? `${order.ShippingAddress[0]?.province},`
                            : ""}
                          {order.ShippingAddress[0]?.country
                            ? `${order.ShippingAddress[0]?.country},`
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="sm-full w:w-1/2 p-4">
                      <p>
                        <strong>Created At:</strong>
                      </p>
                      <p className={styles.subtext}>
                        {moment(order.created_at).format(
                          "MM-DD-YYYY, hh:mm:ss A"
                        )}
                      </p>
                      <div className="flex gap-[10px] mt-2">
                       
                        <p>
                          <span className={styles.fulfillment_status_tag} >
                            {formatName(order.fulfillment_status)}
                          </span>
                        </p>
                        <p>
                          <span className={styles.financial_status_tag}>
                            {formatName(order.financial_status)}
                          </span>
                        </p>
                      </div>
                      <h1 className="text-xl">
                        <strong>{order.name}</strong>
                      </h1>

                      <div style={{ margin: "10px" }}></div>
                      <Barcode
                        value={order.name}
                        width={2}
                        height={50}
                        fontSize={12}
                        margin={5}
                        className="barcode"
                      />
                      <p className="mt-[10px]">
                        <strong>Total orders :</strong>
                        {order.total_orders_by_customer}
                      </p>
                    </div>
                  </div>
                  <div className="sm-full w:w-1/2 p-4 ">
                   

                    <div>
                      Total Line of items :{" "}
                      <span className={styles.subtext}>
                        {order.total_line_items}
                      </span>
                    </div>
                  </div>
                  <div className="mt-4">
                    <table className="w-full border-collapse">
                      <thead>
                        <tr className="border-b border-gray-300">
                          <th className="border-b border-gray-300 p-[8px]">
                            Qty
                          </th>
                          <th className="border-b border-gray-300 p-[8px]">
                            Image
                          </th>
                          <th className="border-b border-gray-300 p-[8px]">
                            Barcode
                          </th>
                          <th className="border-b border-gray-300 p-[8px]">
                            Item
                          </th>
                          <th className="border-b border-gray-300 p-[8px]">
                            Weight
                          </th>
                          <th className="border-b border-gray-300 p-[8px]">
                            Price{" "}
                            <span className={styles.light_text}>
                              ({order.currency})
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.LineItems.map((item, index) => (
                          <tr className="border-b border-gray-300" key={index}>
                            <td
                              style={{ alignContent: "center" }}
                              className="border-b border-gray-300 p-2 text-center"
                            >
                              {item.quantity || ""} x
                            </td>
                            <td
                              style={{ alignContent: "center" }}
                              className="border-b border-gray-300 p-2 text-center"
                            >
                              <img
                                style={{ margin: "auto" }}
                                className="w-12 h-12"
                                src={findProductImage(item)}
                                alt={item.name || ""}
                              />
                              
                            </td>
                            <td
                              style={{ alignContent: "center" }}
                              className="border-b border-gray-300 p-2 text-center"
                            >
                              <div>
                                <div>{item.variant?.sku || item.sku || " "}</div>
                                <div className="flex justify-center items-center">
                                  {item.variant?.barcode ? (
                                    <Barcode
                                      value={item.variant.barcode}
                                      width={1}
                                      height={50}
                                      fontSize={10}
                                      margin={3}
                                      className="barcode"
                                    />
                                  ) : null}
                                </div>
                                <div>
                                  <p style={{ fontSize: "12px" }}>
                                    Location {item.product?.location || ""}
                                  </p>
                                  <p style={{ fontSize: "12px" }}>
                                    Variant Location{" "}
                                    {item.variant?.variant_location || ""}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td
                              style={{ alignContent: "center" }}
                              className="border-b border-gray-300 p-2 text-center"
                            >
                              {item.name || ""}
                              <p className={styles.subtext}>{item.product?.product_type || ""}</p>
                            </td>
                            <td
                              style={{ alignContent: "center" }}
                              className="border-b border-gray-300 p-2 text-center"
                            >
                              {parseFloat((item.grams * item.quantity) / 1000)}{" "}
                              kg
                            </td>
                            <td
                              style={{ alignContent: "center" }}
                              className="border-b border-gray-300 p-2 text-center"
                            >
                              {item.price || ""}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="flex justify-between mb-1 mt-7">
                    <div className="sm-full w:w-1/2 p-4">
                      <p>
                        <strong>Total Quantities: </strong>{" "}
                        <span className={styles.subtext_detail}>
                          {order.total_quantity}
                        </span>
                      </p>
                      <p>
                        <strong>Order Weight</strong>:{" "}
                        <span className={styles.subtext_detail}>
                          {parseFloat(order.total_weight / 1000)} Kgs + Box
                          Weight 90 grams ={" "}
                          {parseFloat((order.total_weight + 90) / 1000)} Kgs
                        </span>
                      </p>
                      <p>
                        <strong>Order value: </strong>
                        <span className={styles.subtext_detail}>
                          {" "}
                          {order.total_price}
                        </span>
                      </p>
                    </div>
                    <div className="sm-full w:w-1/2 p-4">
                      <p>
                        <strong>Assigned to: </strong>
                        <span className={styles.subtext_detail}>
                          {formatName(
                            order.AssignedOrders[0]?.assignTo.full_name
                          )}
                        </span>
                        {/* {formatName(order.AssignedOrders[0]?.assignTo.last_name)} */}
                      </p>
                      <p>
                        <strong>Printed By : </strong>
                        <span className={styles.subtext_detail}>
                          {formatName(data.printedBy)}
                        </span>
                      </p>
                      <p>
                        <strong>Assigned by : </strong>
                        <span className={styles.subtext_detail}>
                          {formatName(order.AssignedOrders[0]?.admin.full_name)}
                        </span>
                        {/* {formatName(order.AssignedOrders[0]?.admin.last_name)} */}
                      </p>
                    </div>
                  </div>
                  <div className="sm-full w:w-1/2 p-4 ">
                    Picklist generated by PickPackGo
                  </div>
                </div>
              </div>
            ))}
          </div>)}
        </div>
      </div>
    </div>
  );
};

export default GeneratePickListByOrderPrintView;