import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Editor from "../../../components/Editor";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import File from "../../../components/File";
import { message } from "antd";
import { generate_product_metadata } from "../../../API/Product";
import { useProduct } from "../../../context/Product/ProductContext";
import { stateToHTML } from "draft-js-export-html";

const NameAndDescription = ({ className }) => {
  const { productMetadata, setProductMetaData } = useProduct();
  const [content, setContent] = useState();
  const [loading, setLoading] = useState(false);
  const [productImage, setProductImage] = useState(null); // Store product_image separately
  const [initialValues, setInitialValue] = useState({
    title: "",
    description: "",
    price: "",
    category: "",
  });

  useEffect(() => {
    setInitialValue({
      title: productMetadata.title,
      description: productMetadata.description,
      price: productMetadata.price,
      category: productMetadata.category,
    });
    if (productMetadata.description) {
      const blocksFromHTML = convertFromHTML(productMetadata.description);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setContent(EditorState.createWithContent(contentState));
    } else {
      setContent(EditorState.createEmpty());
    }
  }, [productMetadata]);

  const validationSchema = Yup.object().shape({
    product_image: Yup.mixed().required("Image is required"),
  });

  const handleSave = async (values, { setTouched, setErrors, validateForm }) => {
    try {
      setLoading(true);
      const errors = await validateForm();
      if (Object.keys(errors).length > 0) {
        setTouched({
          product_image: true,
        });
        setErrors(errors);
        return;
      }

      const formData = new FormData();
      formData.append("image", values.product_image); // Use state to append file to FormData

      const res = await generate_product_metadata(formData);

      if (res.status === 200) {
        setProductMetaData(res.data.data);
        message.success(res.data.message);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const errors = error.response.data.errors;
        message.error(Object.values(errors[0])[0]);
      } else if (
        (error.response.status === 500) |
        (error.response.status === 403)
      ) {
        message.error(error.response.data.err);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      className={cn(styles.card, className)}
      title="Add Product"
      classTitle="title-purple"
      head={
        <Link
          className={cn("button-stroke button-small", styles.button)}
          to="/products/dashboard"
        >
          <Icon name="arrow-left" size="24" />
          <span>Back</span>
        </Link>
      }
    >
      <div className={styles.description}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {({ isSubmitting, setFieldValue, values, errors, touched }) => (
            <Form>
              <div className={styles.field_container}>
                <Field name="product_image">
                  {({ field, form, meta }) => (
                    <>
                      <File
                        field={field}
                        form={form}
                        className={styles.field}
                        title="Click or drop image"
                        label="Media"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          setFieldValue("product_image", file); // Set Formik value
                          setProductImage(file); // Also store in state
                        }}
                      />
                      {meta.touched && meta.error ? (
                        <div className={styles.error}>{meta.error}</div>
                      ) : null}
                    </>
                  )}
                </Field>
              </div>

              <div className={styles.button_container}>
                {loading ? (
                  <button
                    type="submit"
                    className={cn("button", styles.button)}
                    disabled={true}
                  >
                    Generating....
                  </button>
                ) : (
                  <button
                    type="submit"
                    className={cn("button", styles.button)}
                    disabled={false}
                  >
                    Generate Content
                  </button>
                )}
              </div>

              <div className={styles.field_container}>
                <Field name="title">
                  {({ field, meta }) => (
                    <TextInput
                      {...field}
                      className={styles.field}
                      label="Title"
                      name="title"
                      type="text"
                      tooltip="Maximum 100 characters. No HTML or emoji allowed"
                    />
                  )}
                </Field>
              </div>
              <div className={styles.field_container}>
                <Field name="description">
                  {({ form }) => (
                    <Editor
                      state={content}
                      onChange={(editorState) => {
                        setContent(editorState);
                        const contentHTML = stateToHTML(
                          editorState.getCurrentContent()
                        );
                        form.setFieldValue("description", contentHTML); // Set HTML value in Formik
                      }}
                      classEditor={styles.editor}
                      label="Description"
                      tooltip="Maximum 500 characters."
                    />
                  )}
                </Field>
              </div>
              <div className={styles.field_container}>
                <Field name="category">
                  {({ field, meta }) => (
                    <TextInput
                      {...field}
                      className={styles.field}
                      label="Category"
                      name="category"
                      type="text"
                      tooltip="Maximum 100 characters. No HTML or emoji allowed"
                    />
                  )}
                </Field>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Card>
  );
};

export default NameAndDescription;
