import custom_axios from "../axios";
export const generate_product_metadata = async (formData) => {
  try {
    const response = await custom_axios.post(
      `/product/upload-and-generate-metadata`,
      formData
    );
    return response;
  } catch (error) {
    console.error("Failed to generate content:", error);
    throw error;
  }
};
