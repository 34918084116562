import React, { createContext, useState, useContext } from "react";

const ProductContext = createContext();

export const useProduct = () => useContext(ProductContext);

export const ProductProvider = ({ children }) => {
  const [productMetadata, setProductMetaData] = useState({
    product_image: "",
    title: "",
    description: "",
    price: "",
    category: "",
  });

  return (
    <ProductContext.Provider
      value={{
        productMetadata,
        setProductMetaData,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
