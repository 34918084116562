import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useLocation } from "react-router-dom";
import moment from "moment";
import cn from "classnames";
import styles from "./PackingSlip.module.sass";
import Barcode from "react-barcode";
import CustomToast from "../CustomToast";
import { addPrintTags } from "../../API/Orders";
import { GENERATE_PACKING_SLIP } from "../../API/Scanner";

const PackingSlip = () => {
  const showToast = CustomToast();
  const [data, setData] = useState(null);
  const printComponentRef = useRef();
  const location = useLocation();
  const { ids } = location.state || { ids: [] };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (ids.length === 0) {
          showToast("No orders selected for printing", "warning");
          return;
        }
        const response = await GENERATE_PACKING_SLIP({ ids: ids });
        if (response?.data?.data) {
          setData(response.data.data);
        } 
      } catch (error) {
        console.error("Error fetching orders:", error);
        showToast("Failed to generate packing slip", "error");
      }
    };
    fetchData();
  }, [ids]);

 

  useEffect(() => {
    // Hide the text element within the barcode
    const barcodes = document.querySelectorAll(".barcode");
    barcodes.forEach((barcode) => {
      const texts = barcode.querySelectorAll("text");
      texts.forEach((text) => (text.style.display = "none"));
    });
  }, [data]);

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    documentTitle: "Picklist",
    pageStyle: `
    @page {
      size: A4;
      margin: 0 !important;
    }
    @media print {
      body {
        margin: 0 !important;
        padding: 0 !important;
      }
      * {
        box-sizing: border-box;
      }
    }
  `,
  });

  if (!data) {
    return <div>Loading...</div>;
  }
  return (
    <div className="container mx-auto p-4">
    <div className="flex items-center justify-center">
      <button
        className={cn(
          "button",
          styles.button,
          "bg-blue-500 hover:bg-blue-700 text-white font-bold my-4"
        )}
        onClick={handlePrint}
      >
        Print
      </button>
    </div>
    <div ref={printComponentRef}>
      {data?.map((order, orderIndex) => (
        <div key={order?.order_number} className="bg-white p-8 border border-gray-300 mb-8">
          <div className="flex justify-between items-start mb-[5px]">
            {order?.store?.store_logo ? (
              <img
                src={order?.store?.store_logo}
                alt="Store Logo"
                className="w-42 h-22"
              />
            ) : (
              <p>{order?.store?.store_name}</p>
            )}
            <div className="flex flex-col items-end">
            <div className="mb-2">
            <Barcode
                value={order?.order_number}
                width={2}
                height={50}
                fontSize={12}
                margin={5}
                className="barcode"
              />
            </div>
            <p className="font-bold">Order {order?.name}</p>
            <p>{moment(order?.created_at).format("MMMM D, YYYY ")}</p>
            <p> {moment(order?.created_at).format(" h:mm A")}</p>
            <p>{order?.email}</p>
          </div>

          </div>

          <div className={cn("mb-6", styles.subtext)}>
            <p className={cn("font-bold", styles.subtext)}>
              <span className="font-bold text-[black]">Delivery method: </span>
              {order?.delivery_method}
            </p>
            <p className={cn("font-bold", styles.subtext)}>
              <span className="font-bold text-[black]">Payment method: </span>
              {order?.OrderDetails?.payment_gateway_names?.[0]}
            </p>
            <p>
              <span className="font-bold text-[black]">Ship to: </span>
              {order?.ShippingAddress?.first_name} {order?.ShippingAddress?.last_name}
              {order?.ShippingAddress?.address1 && `, ${order?.ShippingAddress?.address1}`}
            </p>
            <p className={styles.subtext}>
              {order?.ShippingAddress?.address2 && `${order?.ShippingAddress?.address2}, `}
              {order?.ShippingAddress?.city}, {order?.ShippingAddress?.province},
              {order?.ShippingAddress?.zip}, {order?.ShippingAddress?.country}
            </p>
          </div>

          <table className="w-full border-collapse mb-6">
            <thead>
              <tr className="border-b border-gray-300">
                <th className="border-b border-gray-300 text-left py-2">S.No.</th>
                <th className="border-b border-gray-300 text-left py-2">Item Name</th>
                <th className="border-b border-gray-300 text-left py-2">SKU</th>
                <th className="border-b border-gray-300 text-left py-2">Qty</th>
                <th className="border-b border-gray-300 text-left py-2">Price (INR)</th>
              </tr>
            </thead>
            <tbody>
              {order?.LineItems?.map((item, index) => (
                <tr key={index} className="border-b border-gray-200">
                  <td className="border-b border-gray-300 text-left py-2">{index + 1}</td>
                  <td className="border-b border-gray-300 text-left py-2">{item?.name}</td>
                  <td className="border-b border-gray-300 text-left py-2">{item?.sku}</td>
                  <td className="border-b border-gray-300 text-left py-2">{item?.quantity}</td>
                  <td className="border-b border-gray-300 text-left py-2">{item?.price}</td>
                </tr>
              ))}
              <tr className="font-bold">
                <td className="border-b border-gray-300 text-left py-2"></td>
                <td colSpan="2" className="border-b border-gray-300 text-left py-2">Total</td>
                <td className="border-b border-gray-300 text-left py-2">{order?.total_quantity}</td>
                <td className="border-b border-gray-300 text-left py-2">{order?.total_price}</td>
              </tr>
            </tbody>
          </table>
          <p className="text-left">Thanks for your order</p>
        </div>
      ))}
    </div>
  </div>
);
};
export default PackingSlip;
