import React, { useState } from "react";
import cn from "classnames";
import styles from "./Price.module.sass";
import Card from "../../../components/Card";
import Tooltip from "../../../components/Tooltip";
import TextInput from "../../../components/TextInput";
import Switch from "../../../components/Switch";
import { useProduct } from "../../../context/Product/ProductContext";
const Price = ({ className }) => {
  const [resolution, setResolution] = useState(true);
  const { productMetadata } = useProduct();
  return (
    <Card
      className={cn(styles.card, className)}
      title="Pricing"
      classTitle="title-blue"
    >
      <div className={styles.price}>
        <TextInput
          className={styles.field}
          label="Amount"
          name="amount"
          type="text"
          tooltip="Small description"
          required
          currency="$"
          value={productMetadata.price}
        />

        <div className={styles.fieldset}>
          <TextInput
            className={styles.field}
            classLabel={styles.label}
            label="Price"
            name="price"
            type="text"
            required
            currency="$"
          />
          <TextInput
            className={styles.field}
            classLabel={styles.label}
            label="Compare at Price"
            name="compare-at-price"
            type="text"
            required
            currency="$"
          />
        </div>
        <div className={styles.fieldset}>
          <TextInput
            className={styles.field}
            classLabel={styles.label}
            label="Cost Per Price"
            name="cost-per-price"
            type="text"
            required
            currency="$"
          />
          <TextInput
            className={styles.field}
            classLabel={styles.label}
            label="Profit"
            name="profit"
            type="text"
            required
            currency="$"
          />
        </div>
        <div className={styles.fieldset}>
          <TextInput
            className={styles.field}
            classLabel={styles.label}
            label="Margin"
            name="margin"
            type="text"
            required
            currency="$"
          />
        </div>
      </div>
    </Card>
  );
};

export default Price;
