import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Shipping.module.sass";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Checkbox from "../../../components/Checkbox";
import { Divider } from "antd";
const Shipping = ({ className }) => {
  const [content, setContent] = useState();

  return (
    <Card
      className={cn(styles.card, className)}
      title="Shipping"
      classTitle="title-purple"
      head={
        <Link
          className={cn("button-stroke button-small", styles.button)}
          to="/products/dashboard"
        >
          <Icon name="arrow-left" size="24" />
          <span>Back</span>
        </Link>
      }
    >
      <Checkbox
        className={styles.checkbox}
        content={"This is a Physical Product"}
      />
      <div className={styles.description}>
        <div className={styles.group}>
          <TextInput
            className={styles.field}
            label="Weight"
            name="value1"
            type="text"
            placeholder="Value"
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            required
          />
        </div>
        <Divider />
        <div className={styles.group_channel}>
          <p className={styles.field}>Add Customer Information</p>
          <button
            className={cn("button-stroke", styles.button, styles.field_button)}
          >
     <Icon name="plus-circle" size="24" />     Add
          </button>
        </div>
        <div className={styles.group_channel}>
          <p className={styles.field}>Add Options Like Size or Color</p>
          
          <button
            className={cn("button-stroke", styles.button, styles.field_button)}
          >
            <Icon name="plus-circle" size="24" />     Add
          </button>
        </div>
      </div>
    </Card>
  );
};

export default Shipping;
