import React, { useState, useEffect } from 'react';
import styles from "./OrdersTable.module.sass";
import Checkbox from "../../../../components/Checkbox";
import Row from "./Row";
import { BiDownArrowAlt, BiUpArrowAlt } from "react-icons/bi";
import cn from "classnames";

const SearchedOrdersTable = ({
    orders,
    checkedItems,
    handleCheckboxChange,
    selectAll,
    handleSelectAll,
    indeterminate,
    onClearSelection,
  }) => {
    const [sortedOrders, setSortedOrders] = useState(orders);
    const [sortField, setSortField] = useState('order_number');
    const [sortOrder, setSortOrder] = useState('asc');
  
    
    useEffect(() => {
         const sortOrdersBySearch = [...orders].sort((a, b) => {      
        const aTimestamp = a.searchTimestamp || 0;
        const bTimestamp = b.searchTimestamp || 0;
        return bTimestamp - aTimestamp;
      });
  
      setSortedOrders(sortOrdersBySearch);
    }, [orders]);
  
    const handleSort = (field) => {
      const order = field === sortField && sortOrder === 'asc' ? 'desc' : 'asc';
      setSortField(field);
      setSortOrder(order);
  
      const sorted = [...sortedOrders].sort((a, b) => {
        if (a[field] < b[field]) return order === 'asc' ? -1 : 1;
        if (a[field] > b[field]) return order === 'asc' ? 1 : -1;
        return 0;
      });
      setSortedOrders(sorted);
    };
    const handleSearchedSelectAll = (e) => {
      const checked = e.target.checked;
      const newCheckedItems = { ...checkedItems };
      sortedOrders.forEach((order) => {
        newCheckedItems[order.id] = checked;
      });
      handleCheckboxChange(e, newCheckedItems);
    };
  
    if (!sortedOrders || sortedOrders.length === 0) return null;
  
    return (
      <div className={styles.searchedOrders}>
      <div className={cn("flex justify-between")}>
        <div className={cn("title-purple mb-[3%]", styles.subtitle)}>
          Selected Orders : {sortedOrders.length}
        </div>
        <button
          className={cn("button-small", styles.button)}
          onClick={onClearSelection}
        >
          Clear Selection
        </button>
      </div>
        <div className="overflow-auto">
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>
                <Checkbox
                  className={styles.checkbox}
                  checked={sortedOrders.every(order => checkedItems[order.id])}
                  onChange={handleSearchedSelectAll}
                  ref={(input) => {
                    if (input) {
                      input.indeterminate = sortedOrders.some(order => checkedItems[order.id]) && !sortedOrders.every(order => checkedItems[order.id]);
                    }
                  }}
                />
              </div>
              {sortedOrders[0] &&
                Object.keys(sortedOrders[0]).map((key, index) => (
                  <div
                    className={cn("whitespace-nowrap flex items-center cursor-pointer",styles.col)}
                    key={index}
                    onClick={() => handleSort(key)}
                  >
                    {key
                      .split("_")
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(" ")}
                    <span>
                      {sortField === key ? (
                        sortOrder === "asc" ? (
                          <BiUpArrowAlt
                            size="20px"
                            className="inline-block"
                          />
                        ) : (
                          <BiDownArrowAlt
                            size="20px"
                            className="inline-block"
                          />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                ))}
            </div>
            {sortedOrders.map((order, index) => (
              <Row
                key={index}
                data={order}
                checkedItems={checkedItems}
                handleCheckboxChange={handleCheckboxChange}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };
export default SearchedOrdersTable;
