import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../../../components/Checkbox";

const Row = ({
  item,
  value,
  onChange,
  activeTable,
  setActiveTable,
  activeId,
  setActiveId,
  onEditClick,
}) => {
  const handleClick = (id) => {
    setActiveTable(true);
    setActiveId(id);
  };

  return (
    <div
      className={cn(
        styles.row,
        { [styles.selected]: activeId === item.id },
        { [styles.active]: activeTable }
      )}
      onClick={() => onEditClick(item.id)}
    >
      <div className={styles.col}>
      </div>
      <div className={styles.col}>
        <div className={styles.item} onClick={() => handleClick(item.id)}>
          {/* <div className={styles.avatar}>
            <img src="/images/content/avatar-1.jpg" alt="Avatar" />
          </div> */}
          <div className={styles.details}>
            <div className={styles.user}>{item.full_name}</div>
            {/* <div className={styles.login}>@username</div> */}
            <div className={styles.email}>{item.email}</div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.email}>{item.email}</div>
      </div>
      <div className={styles.col}>
        <div
          className={cn(styles.purchase, {
            "status-green-dark":
              item.role.role === "Admin" || item.role.role === "Super Admin",
            "status-orange-dark": item.role.role === "Picker",
            "status-purple-dark": item.role.role === "Packer",
            "status-blue-dark":
              item.role.role !== "Admin" &&
              item.role.role !== "Super Admin" &&
              item.role.role !== "Picker" &&
              item.role.role !== "Packer",
          })}
        >
          {item.role.role}
        </div>
      </div>
      <div className={styles.col}>
        <div
          className={cn(styles.purchase, {
            "status-role-active": item.status === true,
            "status-role-inactive": item.status === false,
          })}
        >
          {item.status === true ? "Active" : "Inactive"}
        </div>
      </div>

      <div className={styles.col}>{item._count.assignedOrders}</div>
      <div className={styles.col}></div>
    </div>
  );
};

export default Row;
