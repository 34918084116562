import custom_axios from "../axios";

export const GENERATE_OREDER_MEDIA_PRESIGNEDURL = async (filesArray) => {
  try {
    return await custom_axios.post(
      "/order/generate-order-media-presigned-url",
      { files: filesArray }
    );
  } catch (error) {
    throw error;
  }
};

export const UPLOAD_MEDIA = async (orderId, imageMetadata) => {
  try {
    return await custom_axios.post(`/order/upload-media`, {
      order_id: orderId,
      media: imageMetadata,
    });
  } catch (error) {
    throw error;
  }
};

export const GET_CAMERAS = async () => {
  try {
    const response = await custom_axios.get(`/camera`);
    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error(response.data.message || "Error fetching cameras");
    }
  } catch (err) {
    throw new Error("Network error fetching cameras");
  }
};

export const GET_ORDERMEDIA = async (orderId) => {
  try {
    const response = await custom_axios.get(`/order/order-media/${orderId}`);
    return response.data;
  } catch (error) {
    throw new Error("Network error fetching data");
  }
};
export const DELETE_SINGELORDER_MEDIA = async (mediaId) => {
  try {
    const response = await custom_axios.delete(
      `/order/single-order-media/${mediaId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const DELETE_ALL_ORDER_MEDIA = async (orderId) => {
  try {
    const response = await custom_axios.delete(
      `/order/all-order-media/${orderId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const GET_ALL_CAMERA_SETTINGS = async () => {
  try {
    const response = await custom_axios.get(`/camera/setting/all`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const FETCH_ORDER_DETAILS = async (orderNumber) => {
  try {
    const response = await custom_axios.post("/order/order-capture-details", {
      order_number: orderNumber,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const UPLOAD_ORDER_MEDIA_METADATA = async (requestBody) => {
  try {
    return await custom_axios.post("/order/capture-order-media", requestBody);
  } catch (error) {
    throw error;
  }
};

export const PACK_ORDERS = async (orderData) => {
  try {
    return await custom_axios.post("/order/pack_orders", orderData);
  } catch (error) {
    throw error;
  }
};
export const UPDATE_INVENTORY = async (orderData) => {
  try {
    return await custom_axios.post("/order/return-order", orderData);
  } catch (error) {
    throw error;
  }
};
export const COMPLETE_PACK_ORDERS = async (orderData) => {
  try {
    return await custom_axios.post("/order/complete_pack_orders", orderData);
  } catch (error) {
    throw error;
  }
};
export const COMPLETE_UNPACK_ORDERS = async (orderData) => {
  try {
    return await custom_axios.post("/order/complete_unpack_orders", orderData);
  } catch (error) {
    throw error;
  }
};
export const GET_TAG_BY_ACTION_BARCODE = async (barcode) => {
  try {
    return await custom_axios.get(`/store-settings/tags/barcode_by_name/${barcode}`);
  } catch (error) {
    throw error;
  }
};
export const GENERATE_PACKING_SLIP = async (data) => {
  try {
    return await custom_axios.post("/order/order-packing-slip", data);
  } catch (error) {
    throw error;
  }
};

export const GET_VARIANT_LOCATION = async () => {
  try {
    const response = await custom_axios.get("/variant-location");
    const locationData = response.data.data.map((loc) => ({
      value: loc.location_id,
      label: loc.name,
    }));
    return locationData;
  } catch (error) {
    throw error;
  }
};
export const GET_DEFAULT_LOCATION = async () => {
  try {
    const response = await custom_axios.get("/employee/warehouse/locations");
    const defaultLocationId = response.data.result?.location?.name || null;
    return defaultLocationId;
  } catch (error) {
    console.error("Error fetching default location:", error);
    return null;
  }
};
export const UPDATE_WAREHOUSE_LOCATION = async (locationId) => {
  try {
    const payload = {
      location_id: locationId,
    };
    const response = await custom_axios.post("/employee/warehouse/locations", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const VERIFY_PRODUCTS = async (formData) => {
  try {
    const response = await custom_axios.post(
      "/order/verify-scan-order",
      formData
    );
    return response;
  } catch (error) {
    throw error;
  }
};
