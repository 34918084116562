import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Update.module.sass";

import Icon from "../../../../components/Icon";
import TextInput from "../../../../components/TextInput";

import { useMenu } from "../../../../context/Roles/MenuContext";
import {
  update_tags_barcode,
  get_one_tags_barcode,
} from "../../../../API/TagBarcode";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import Card from "../../../../components/Card";
import { useFormikContext } from "../../context/SettingsContext";

const UpdateBarcode = () => {
  const { formikRef } = useFormikContext();
  const { setSubActiveTab, tagBarcode } = useMenu();

  const [initialValues, setInitialValues] = useState({
    barcode: "",
    tag_name: "",
  });

  const validationSchema = Yup.object({
    barcode: Yup.string().required("Barcode is required"),
    tag_name: Yup.string().required("Tag name is required"),
  });

  const handleSave = async (
    values,
    { setTouched, setErrors, validateForm }
  ) => {
    try {
      const errors = await validateForm();
      if (Object.keys(errors).length > 0) {
        setTouched({
          tag_name: true,
          barcode: true,
        });
        setErrors(errors);
        return;
      }

      const formData = {
        id: tagBarcode.id,
        barcode: values.barcode,
        tag_name: values.tag_name,
      };

      const res = await update_tags_barcode(formData);

      if (res.status === 201) {
        setSubActiveTab(1);

        message.success(res.data.message);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const errors = error.response.data.errors;
        message.error(Object.values(errors[0])[0]);
      } else if (error.response.status === 500) {
        message.error(error.response.data.err);
      }
    }
  };

  useEffect(() => {
    const fetchBarcodeDetails = async () => {
      try {
        const response = await get_one_tags_barcode(tagBarcode.id);
        const barcodeDetails = response.data;

        setInitialValues({
          barcode: barcodeDetails.barcode,
          tag_name: barcodeDetails.tag_name,
        });
      } catch {
        message.error("Failed to fetch barcode details.");
      }
    };

    fetchBarcodeDetails();
  }, [tagBarcode.id]);

  const handleBack = () => {
    setSubActiveTab(1);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSave}
      innerRef={formikRef}
    >
      {({ isSubmitting, setFieldValue, values, errors, touched }) => (
        <Form>
          <Card
            className={cn(styles.card)}
            title="Edit Action Barcode for Tags"
            classTitle="title-red"
            head={
              <div className={styles.flex_box}>
                <button className={cn("button-stroke")} onClick={handleBack}>
                  <Icon name="arrow-left" size="24" />
                  <span>Back</span>
                </button>
              </div>
            }
          >
            <div className={styles.description}>
              <div className={styles.txt_container}>
                <Field name="barcode">
                  {({ field, meta }) => (
                    <TextInput
                      {...field}
                      label="Enter Barcode"
                      tooltip="Maximum 50 characters."
                      className={cn(styles.field)}
                      error={meta.touched && meta.error ? meta.error : ""}
                      required
                      disabled
                    />
                  )}
                </Field>
              </div>

              <div className={styles.txt_container}>
                <Field name="tag_name">
                  {({ field, meta }) => (
                    <TextInput
                      {...field}
                      label="Enter Tag"
                      tooltip="Maximum 50 characters."
                      className={cn(styles.field)}
                      error={meta.touched && meta.error ? meta.error : ""}
                      required
                    />
                  )}
                </Field>
              </div>
            </div>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateBarcode;
