import React, { useState } from "react";
import cn from "classnames";
import styles from "./Preview.module.sass";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import Dropdown from "../../../components/Dropdown";
import TextInput from "../../../components/TextInput";
import Radio from "../../../components/Radio";
import {  Space } from "antd";

const Preview = ({ visible, onClose }) => {
  const optionsCategory = ["Select category", "Category 1", "Category 2"];
  const salesChannel = ["Online Store", "Point of Sale"];

  const [category, setCategory] = useState(optionsCategory[0]);
  return (
    <>
      <div className={cn(styles.preview, { [styles.visible]: visible })}>
        <Card
          className={styles.card}
          classCardHead={styles.head}
          title="Status"
          classTitle="title-blue"
        >
          <Dropdown
            className={styles.field}
            label="Status"
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            value={category}
            setValue={setCategory}
            options={optionsCategory}
          />
        </Card>

        <Card
          className={styles.card}
          classCardHead={styles.head}
          title="Publishing"
          classTitle="title-green"
        >
      
          <p className="mb-3">Sales Channel</p>
          <div className={styles.description}>
          <Space direction="vertical">
            {salesChannel.map((channel) => (
              <Radio key={channel} content={channel} name="staff" reverse />
            ))}
          </Space>
          </div>
        </Card>

        <Card
          className={styles.card}
          classCardHead={styles.head}
          title="Product Organization"
          classTitle="title-purple"
        >
          <div className={styles.description}>
            <TextInput
              className={styles.field}
              label="Product Type"
              name="product-type"
              type="text"
              tooltip="Maximum 100 characters. No HTML or emoji allowed"
              required
            />

            <TextInput
              className={styles.field}
              label="Vendors"
              name="vendors"
              type="text"
              tooltip="Maximum 100 characters. No HTML or emoji allowed"
              required
            />
            <TextInput
              className={styles.field}
              label="Collections"
              name="collections"
              type="text"
              tooltip="Maximum 100 characters. No HTML or emoji allowed"
              required
            />
            <TextInput
              className={styles.field}
              label="Tags"
              name="tags"
              type="text"
              tooltip="Maximum 100 characters. No HTML or emoji allowed"
              required
            />
            <TextInput
              className={styles.field}
              label="Theme Template"
              name="tags"
              type="text"
              tooltip="Maximum 100 characters. No HTML or emoji allowed"
              required
            />
          </div>
        </Card>
      </div>
    </>
  );
};

export default Preview;
