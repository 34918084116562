import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import styles from "./Index.module.sass";
import Card from "../../components/Card";
import Form from "../../components/Form";
import Panel from "./Panel";
import OrderLineItems from "./OrderLineItems/index";
import { message } from "antd";
import CameraImage from "../../assets/images/camera2.png";
import {
  FETCH_ORDER_DETAILS,
  GET_CAMERAS,
  GET_ALL_CAMERA_SETTINGS,
  GENERATE_OREDER_MEDIA_PRESIGNEDURL,
  UPLOAD_ORDER_MEDIA_METADATA,
  DELETE_ALL_ORDER_MEDIA,
  DELETE_SINGELORDER_MEDIA,
  PACK_ORDERS,
  GET_TAG_BY_ACTION_BARCODE,
  UPDATE_WAREHOUSE_LOCATION,
  GET_DEFAULT_LOCATION,
  GET_VARIANT_LOCATION,
  VERIFY_PRODUCTS
} from "../../API/Scanner";
import { GET_EMPLOYEE } from "../../API/Employee";
import Product from "./Product";
import Swal from "sweetalert2";
import Record from "../../assets/images/record.png";
import CustomToast from "../../components/CustomToast";
import Loader from "../../components/Loader";
import Image from "../../components/Image";
import errorSound from "../../assets/Sounds/error.wav";
import captureSound from "../../assets/Sounds/capture-beep.mp3";
import successSound from "../../assets/Sounds/Success.wav";
import VideoSound from "../../assets/Sounds/video-beep.mp3";
import CustomAlert from "../../components/CustomAlert";
import { useLocation, useNavigate } from "react-router";
import Dropdown from "../../components/Dropdown";
import Icon from "../../components/Icon";

const ScanOrderIndex = () => {
  const showToast = CustomToast();
  const [searchInput, setSearchInput] = useState("");
  const [currentOrder, setCurrentOrder] = useState(null);
  const [productVerified,setProductVerified] = useState([])
  const [cameras, setCameras] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [capturedPhotos, setCapturedPhotos] = useState([]);
  const [recordedVideos, setRecordedVideos] = useState([]);
  const [activeRecordings, setActiveRecordings] = useState(new Set());
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [scannedBarcode, setScannedBarcode] = useState("");
  const [newcapturedPhotos, setNewCapturedPhotos] = useState([]);
  const [activeBarcode, setActiveBarcode] = useState(null);
  const [canSearchNewOrder, setCanSearchNewOrder] = useState(true);
  const [employee, setEmployee] = useState(null);
  const videoRefs = useRef([]);
  const mediaRecorder = useRef([]);
  const chunks = useRef({});
  const [cameraStreams, setCameraStreams] = useState({});
  const [mediaUploaded, setMediaUploaded] = useState(false);
  const { showAlert } = CustomAlert();
  const errorAudio = useRef(new Audio(errorSound));
  const captureAudio = useRef(new Audio(captureSound));
  const VideoAudio = useRef(new Audio(VideoSound));
  const successAudio = useRef(new Audio(successSound));
  const [recordingStartTime, setRecordingStartTime] = useState(null);
  const recordingTimerRef = useRef(null);
  const alertTimerRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [tagId, setTagId] = useState(null);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(
    "Select the location"
  );
  const [isLocationSelected, setIsLocationSelected] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isRecording) {
        event.preventDefault();
        return (event.returnValue =
          "Recording in Progress. Are you sure you want to go back? Your recording will be lost.");
      }
    };
    const handlePopState = (event) => {
      if (isRecording) {
        const userConfirmed = window.confirm(
          "Recording in Progress. Are you sure you want to go back? Your recording will be lost."
        );
        if (!userConfirmed) {
          window.history.pushState(null, "", location.pathname);
        }
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);
    const unlisten = () => {
      const originalPushState = window.history.pushState;
      window.history.pushState = function (state, title, url) {
        if (isRecording) {
          const userConfirmed = window.confirm(
            "Recording in Progress. Are you sure you want to go back? Your recording will be lost."
          );
          if (!userConfirmed) {
            return;
          }
        }
        originalPushState.apply(this, arguments);
      };
      return () => {
        window.history.pushState = originalPushState;
      };
    };
    const cleanUp = unlisten();
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
      cleanUp();
    };
  }, [isRecording, location.pathname, navigate]);
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const data = await GET_EMPLOYEE();
        setEmployee(data);
      } catch (error) {
        console.error("Failed to fetch employees:", error);
      }
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    const fetchEmployeeAndInitializeCameras = async () => {
      try {
        const employeeData = await GET_EMPLOYEE();
        setEmployee(employeeData);

        const fetchCamerasAndInitialize = async () => {
          try {
            let storedCameras = JSON.parse(
              localStorage.getItem("Camera") || "[]"
            );
            if (storedCameras.length === 0) {
              const camerasData = await GET_CAMERAS();
              storedCameras = camerasData;
              localStorage.setItem("Camera", JSON.stringify(camerasData));
            }
            setCameras(storedCameras);
            initializeCameras(storedCameras);
          } catch (error) {
            console.error("Error fetching or initializing cameras:", error);
            showToast("Failed to load camera information", "error");
          }
        };

        await fetchCamerasAndInitialize();
      } catch (error) {
        console.error("Failed to fetch employee data:", error);
        showToast("Failed to load employee data", "error");
      }
    };

    fetchEmployeeAndInitializeCameras();

    return () => {
      Object.values(cameraStreams).forEach((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      });
    };
  }, []);

  const initializeCameras = async (camerasToInitialize) => {
    try {
      const streams = {};
      for (const camera of camerasToInitialize) {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: { exact: camera.deviceId } },
          width: { ideal: 1920 },
          height: { ideal: 1080 },
          frameRate: { ideal: 30 },
          aspectRatio: { ideal: 16 / 9 },
        });
        const videoTrack = stream.getVideoTracks()[0];
        await videoTrack.applyConstraints({
          width: { ideal: 1920 },
          height: { ideal: 1080 },
          frameRate: { ideal: 30 },
        });
        streams[camera.deviceId] = stream;
      }
      setCameraStreams(streams);
    } catch (error) {
      console.error("Error initializing camera streams:", error);
      showToast("Failed to initialize camera streams", "error");
    }
  };

  useEffect(() => {
    Object.entries(cameraStreams).forEach(([deviceId, stream]) => {
      const index = cameras.findIndex((camera) => camera.deviceId === deviceId);
      if (index !== -1 && videoRefs.current[index]) {
        videoRefs.current[index].srcObject = stream;
        videoRefs.current[index]
          .play()
          .catch((e) => console.error("Error playing video:", e));
      }
    });
    return () => {
      Object.values(cameraStreams).forEach((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      });
    };
  }, [cameraStreams, cameras]);

  useEffect(() => {
    const loadLocations = async () => {
      try {
        const locationData = await GET_VARIANT_LOCATION();
        setLocations(locationData);
      } catch (error) {
        console.error("Error fetching locations:", error);
        setError("Failed to fetch locations");
      }
    };

    const loadDefaultLocation = async () => {
      try {
        const defaultLocationId = await GET_DEFAULT_LOCATION();
        if (defaultLocationId) {
          setSelectedLocation(defaultLocationId);
          setIsLocationSelected(true);
        } else {
          setIsLocationSelected(false);
        }
      } catch (error) {
        console.error("Error fetching default location:", error);
        setError("Failed to fetch default location");
        setIsLocationSelected(false);
      }
    };

    loadLocations();
    loadDefaultLocation();
  }, []);

  const handleLocationChange = async (selectedLabel) => {
    const selectedLocationObject = locations.find(
      (loc) => loc.label === selectedLabel
    );
    if (selectedLocationObject) {
      const locationId = selectedLocationObject.value;
      setSelectedLocation(selectedLabel);
      setIsLocationSelected(true);
      try {
        const response = await UPDATE_WAREHOUSE_LOCATION(locationId);
      } catch (error) {
        console.error("Error updating location:", error);
        setError("Failed to update location");
      }
    } else {
      console.error("Selected location not found");
      setError("Invalid location selected");
      setIsLocationSelected(false);
    }
  };
  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    if (!isLocationSelected) {
      showAlert(
        "Location not selected",
        "Please select a location before scanning an order.",
        "warning",
        "OK",
        null,
        0,
        false,
        false,
        false
      );
      setLoading(false);
      return;
    }
    try {
      if (canSearchNewOrder) {
        const response = await FETCH_ORDER_DETAILS(searchInput);
        if (response.status === 200) {
          const orderData = response.data.data;
          setCurrentOrder(orderData);
          setSelectedProducts([]);
          setCanSearchNewOrder(false);
          showToast("Order fetched successfully", "success");
          successAudio.current.play();
        } else {
          showToast("An error occurred", "error");
          errorAudio.current.play();
        }
      } else {
        if (/^BAR_\d{3}$/.test(searchInput)) {
          await handleCameraMode(searchInput);
        } else if (/^TAG_/.test(searchInput)) {
          try {
            const response = await GET_TAG_BY_ACTION_BARCODE(searchInput);
            if (response.status === 200) {
              const tagData = response.data.data;
              setTagId(tagData.id);
              showToast(`Tag found: ${tagData.tag_name}`, "success");
              successAudio.current.play();
            } else {
              showToast("Tag not found", "error");
              errorAudio.current.play();
            }
          } catch (error) {
            console.error("Error fetching tag:", error);
            showToast("Error fetching tag", "error");
            errorAudio.current.play();
          }
        } else {
          handleSKUScan(searchInput);
        }
      }
    } catch (error) {
      console.error("Error in handleSearch:", error);
      showToast(error.response.data.err, "error");
      errorAudio.current.play();
    } finally {
      setLoading(false);
      setSearchInput("");
    }
  };

  const handleSKUScan = (sku) => {
    const matchingSku = currentOrder.LineItems.find(
      (item) => item.sku === sku || item.barcode === sku
    );
    if (matchingSku) {
      setScannedBarcode(sku);
      showToast(`Scanned SKU: ${matchingSku.name}`, "success");
      successAudio.current.play();
      setTimeout(() => {
        setScannedBarcode("");
      }, 100);
    } else {
      showToast("SKU is not matching", "error");
      errorAudio.current.play();
    }
  };
   const handleCameraMode = async (barcode) => {
    try {
      const cameraResponse = await GET_ALL_CAMERA_SETTINGS();
      if (cameraResponse.status === 200) {
        const camerasData = cameraResponse.data.data;
        let foundCameras =
          camerasData.find((setting) => setting.barcode_value === barcode)
            ?.cameras || [];

        if (foundCameras.length > 0) {
          const camerasToStart = [];
          const camerasToCapture = [];
          const isStoppingRecording = activeBarcode === barcode;

          for (let backendCamera of foundCameras) {
            const localCamera = cameras.find((c) => c.id === backendCamera.id);
            if (!localCamera) continue;
            if (backendCamera.mode === "PHOTO") {
              camerasToCapture.push(localCamera.deviceId);
            }
            else if (
              backendCamera.mode === "VIDEO" ||
              backendCamera.mode === "BOTH"
            ) {
              if (activeBarcode && barcode !== activeBarcode) {
                showToast(
                  "Cannot start new recording while another is in progress",
                  "error"
                );
                errorAudio.current.play();
                return;
              }
              camerasToStart.push(localCamera.deviceId);
            }
            if (backendCamera.mode === "BOTH" && !isStoppingRecording) {
              camerasToCapture.push(localCamera.deviceId);
            }
          }
          if (camerasToCapture.length > 0 && !isStoppingRecording) {
            capturePhoto(camerasToCapture);
          }
          if (camerasToStart.length > 0) {
            if (isStoppingRecording) {
              await stopAllRecordings();
              setActiveBarcode(null);
              showToast(`Stopped recording for barcode: ${barcode}`, "success");
              successAudio.current.play();
            } else {
              startRecording(camerasToStart);
              setActiveBarcode(barcode);
              message.success(`Camera mode activated for barcode: ${barcode}`);
            }
          }
        } else {
          showToast(`No cameras found for barcode: ${barcode}`, "error");
          errorAudio.current.play();
        }
      } else {
        setError(
          cameraResponse.data.message || "Error fetching camera settings"
        );
        errorAudio.current.play();
      }
    } catch (err) {
      console.error("Error in handleCameraMode:", err);
      setError("Network error fetching camera settings");
      errorAudio.current.play();
    }
  };
  const stopAllRecordings = async () => {
    const activeDevices = Array.from(activeRecordings);
    if (activeDevices.length > 0) {
      await stopRecording(activeDevices);
    }
  };

  const capturePhoto = async (cameraIds = []) => {
    const capturePromises = cameras.map(async (camera, index) => {
      if (
        videoRefs.current[index] &&
        (cameraIds.length === 0 || cameraIds.includes(camera.deviceId))
      ) {
        const video = videoRefs.current[index];

        try {
          if ("ImageCapture" in window) {
            const videoTrack = video.srcObject.getVideoTracks()[0];
            const imageCapture = new ImageCapture(videoTrack);
            const bitmap = await imageCapture.grabFrame();
            const canvas = document.createElement("canvas");
            canvas.width = 1920;
            canvas.height = 1080;

            const ctx = canvas.getContext("2d");
            ctx.drawImage(bitmap, 0, 0, canvas.width, canvas.height);
            ctx.font = "bold 24px Arial"; 
            ctx.fillStyle = "#FFFFFF";
            ctx.textAlign = "left";
            ctx.fillText(` By : ${employee.full_name}`, 20, canvas.height - 20);

            const currentDate = new Date();
            const currentTimestamp = `${currentDate.getDate()}-${
              currentDate.getMonth() + 1
            }-${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()} ${
              currentDate.getHours() >= 12 ? "PM" : "AM"
            }`;

            ctx.textAlign = "right";
            ctx.fillText(
              `Time : ${currentTimestamp}`,
              canvas.width - 20,
              canvas.height - 20
            );
            return new Promise((resolve) => {
              canvas.toBlob(
                (blob) => {
                  if (blob) {
                    const newPhoto = {
                      cameraId: camera.deviceId,
                      imageBlob: blob,
                      imageUrl: URL.createObjectURL(blob),
                    };
                    resolve(newPhoto);
                  }
                },
                "image/png",
                1.0
              ); 
            });
          } else {
            // Fallback to canvas API if ImageCapture is not available
            const canvas = document.createElement("canvas");
            canvas.width = 1920;
            canvas.height = 1080;

            const ctx = canvas.getContext("2d");
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            ctx.font = "bold 24px Arial";
            ctx.fillStyle = "#FFFFFF";
            ctx.textAlign = "left";
            ctx.fillText(` By : ${employee.full_name}`, 20, canvas.height - 20);

            const currentDate = new Date();
            const currentTimestamp = `${currentDate.getDate()}-${
              currentDate.getMonth() + 1
            }-${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()} ${
              currentDate.getHours() >= 12 ? "PM" : "AM"
            }`;

            ctx.textAlign = "right";
            ctx.fillText(
              `Time : ${currentTimestamp}`,
              canvas.width - 20,
              canvas.height - 20
            );

            return new Promise((resolve) => {
              canvas.toBlob(
                (blob) => {
                  if (blob) {
                    const newPhoto = {
                      cameraId: camera.deviceId,
                      imageBlob: blob,
                      imageUrl: URL.createObjectURL(blob),
                    };
                    resolve(newPhoto);
                  }
                },
                "image/png",
                1.0
              );
            });
          }
        } catch (error) {
          console.error(
            `Error capturing photo for camera ${camera.name}:`,
            error
          );
          return null;
        }
      }
      return null;
    });

    const capturedPhotos = await Promise.all(capturePromises);
    const validPhotos = capturedPhotos.filter((photo) => photo !== null);

    if (validPhotos.length > 0) {
      setCapturedPhotos((prev) => [...prev, ...validPhotos]);
      captureAudio.current.play();
      const mediaData = validPhotos.map((photo) => ({
        cameraId: photo.cameraId,
        type: "image",
        blob: photo.imageBlob,
        fileName: `ppg`,
      }));

      await uploadMedia(mediaData);
    }
  };
  const startRecording = (cameraIds = []) => {
    cameras.forEach((camera, index) => {
      if (
        videoRefs.current[index] &&
        (cameraIds.length === 0 || cameraIds.includes(camera.deviceId))
      ) {
        const stream = videoRefs.current[index].srcObject;
        if (stream && stream.active) {
          if (!mediaRecorder.current[index]) {
            // Configure high-quality video recording
            const options = {
              mimeType: "video/webm;codecs=vp9",
              videoBitsPerSecond: 8000000, // 8 Mbps for high quality
              videoWidth: 1920,
              videoHeight: 1080,
            };

            mediaRecorder.current[index] = new MediaRecorder(stream, options);
            chunks.current[camera.deviceId] = [];

            mediaRecorder.current[index].ondataavailable = (e) => {
              if (e.data.size > 0) {
                chunks.current[camera.deviceId].push(e.data);
              }
            };
            mediaRecorder.current[index].start(100);

            setActiveRecordings((prev) => new Set(prev).add(camera.deviceId));
            setIsRecording(true);
          }
        }
      }
    });
    setRecordingStartTime(Date.now());
    startRecordingTimer();
    VideoAudio.current.play();
  };
  const stopRecording = async (cameraIds = []) => {
    const stoppingPromises = [];

    cameras.forEach((camera, index) => {
      if (
        videoRefs.current[index] &&
        (cameraIds.length === 0 || cameraIds.includes(camera.deviceId))
      ) {
        if (
          mediaRecorder.current[index] &&
          mediaRecorder.current[index].state === "recording"
        ) {
          mediaRecorder.current[index].stop();

          stoppingPromises.push(
            new Promise((resolve) => {
              mediaRecorder.current[index].onstop = () => {
                const blob = new Blob(chunks.current[camera.deviceId], {
                  type: "video/webm",
                });
                resolve({ cameraId: camera.deviceId, videoBlob: blob });
              };
            })
          );

          mediaRecorder.current[index] = null;
          setActiveRecordings((prev) => {
            const newSet = new Set(prev);
            newSet.delete(camera.deviceId);
            return newSet;
          });
        }
      }
    });

    const newRecordedVideos = await Promise.all(stoppingPromises);
    setRecordedVideos((prevVideos) => [...prevVideos, ...newRecordedVideos]);
    setActiveRecordings((prev) => {
      if (prev.size === 0) {
        setIsRecording(false);
        clearRecordingTimer();
      }
      return prev;
    });

    const mediaData = newRecordedVideos.map((video) => ({
      cameraId: video.cameraId,
      type: "video",
      blob: video.videoBlob,
      fileName: `ppg`,
    }));

    if (mediaData.length > 0) {
      await uploadMedia(mediaData);
    }
  };
  const startRecordingTimer = () => {
    recordingTimerRef.current = setTimeout(() => {
      showRecordingAlert();
    }, 10 * 60 * 1000);
  };

  const clearRecordingTimer = () => {
    if (recordingTimerRef.current) {
      clearTimeout(recordingTimerRef.current);
    }
    if (alertTimerRef.current) {
      clearTimeout(alertTimerRef.current);
    }
    setRecordingStartTime(null);
  };
  const showRecordingAlert = () => {
    showAlert(
      "Recording Time Limit Reached",
      "You’ve been recording for over 10 minutes. To avoid unnecessary recording, the session will auto-stop in 10 seconds unless you choose to continue",
      "warning",
      "Continue Recording",
      "Stop Recording",
      10000,
      true,
      false,
      true
    ).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer || result.isDismissed) {
        stopAllRecordings();
      } else if (result.isConfirmed) {
        startRecordingTimer();
      }
    });
  };

  useEffect(() => {
    return () => {
      clearRecordingTimer();
    };
  }, []);
  const uploadMedia = async (mediaData) => {
    try {
      setLoading(true);
      const response = await GENERATE_OREDER_MEDIA_PRESIGNEDURL(mediaData);
      const urls = response.data;

      const uploadPromises = mediaData.map(async (media, index) => {
        const { signedUrl } = urls[index];
        try {
          setLoading(true);
          await fetch(signedUrl, {
            method: "PUT",
            headers: {
              "Content-Type": media.blob.type,
            },
            body: media.blob,
          });

          const mediaMetadata = {
            order_id: currentOrder.id,
            type: "order_media",
            media: {
              type: media.type,
              url: urls[index].fileUrl,
            },
          };

          await UPLOAD_ORDER_MEDIA_METADATA(mediaMetadata);
          setLoading(true);
          return mediaMetadata;
        } catch (error) {
          console.error(
            `Error uploading media ${index} to presigned URL:`,
            error
          );
          throw new Error(`Failed to upload media ${index} to presigned URL`);
        }
      });

      const uploadedMediaMetadata = await Promise.all(uploadPromises);
      showToast("Media uploaded successfully", "success");
      setMediaUploaded(true);

      
      if (mediaData[0].type === "image") {
        const formData = new FormData();
        formData.append("image", mediaData[0].blob);

        formData.append("orderData", JSON.stringify(currentOrder));
        const verifyProduct = await VERIFY_PRODUCTS(formData);
        const productsToAdd = verifyProduct?.data?.status
        .filter((item) => item.quantity > 0)
        .map((item) => item.product_id);
        setProductVerified((prevVerified) => {
          const newProducts = productsToAdd.filter((id) => !prevVerified.includes(id));
          return [...prevVerified, ...newProducts];
        });
       
      }
    
   



    } catch (error) {
      console.error("Error uploading media:", error);
      showToast("Failed to upload media", "error");
    } finally {
      setLoading(false);
    }
  };

  const markAsPacked = async () => {
    try {
      setLoading(true);
      const requestBody = {
        order_id: currentOrder.id,
        products: selectedProducts,
      };
      if (tagId) {
        requestBody.tag_id = tagId;
      }
      const response = await PACK_ORDERS(requestBody);
      if (response.status === 200) {
        setCanSearchNewOrder(true);
        setCapturedPhotos([]);
        setNewCapturedPhotos([]);
        setRecordedVideos([]);
        setCurrentOrder(null);
        setSearchInput("");
        setSelectedProducts([]);
        setScannedBarcode("");
        setActiveBarcode(null);

        Swal.fire({
          icon: "success",
          title: "Order Packed",
          text: "Order marked as packed successfully.",
          confirmButtonColor: "#3085d6",
        });
      } else {
        throw new Error(response.data.message || "Error packing order");
      }
    } catch (error) {
      console.error("Error in markAsPacked:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error marking the order as packed. Please try again.",
        confirmButtonColor: "#3085d6",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveAll = async () => {
    const confirmRemoveAll = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This action will remove all media associated with this order. This cannot be undone.",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove all media!",
    });

    if (confirmRemoveAll.isConfirmed) {
      try {
        const response = await DELETE_ALL_ORDER_MEDIA(currentOrder.id);

        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            text: "All media removed successfully.",
          });
          setCurrentOrder((prevData) => ({
            ...prevData,
            video_count: 0,
            image_count: 0,
            OrderMedia: [],
          }));
        } else {
          Swal.fire({
            icon: "error",
            text: response.data.message || "Error removing media",
          });
        }
      } catch (err) {
        console.log(err);
        Swal.fire({
          icon: "error",
          text: "An error occurred while removing media.",
        });
      }
    }
  };

  const handleDelete = async (mediaId) => {
    const confirmDelete = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This action cannot be undone.",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmDelete.isConfirmed) {
      try {
        const response = await DELETE_SINGELORDER_MEDIA(mediaId);

        if (response.status === 200) {
          setCurrentOrder((prevOrder) => ({
            ...prevOrder,
            OrderMedia: prevOrder.OrderMedia.filter(
              (item) => item.id !== mediaId
            ),
          }));

          Swal.fire({
            icon: "success",
            text: "Media deleted successfully.",
          });

          const updatedOrderResponse = await FETCH_ORDER_DETAILS(
            currentOrder.order_numberF
          );
          if (updatedOrderResponse.status === 200) {
            setCurrentOrder(updatedOrderResponse.data.data);
          }
        } else {
          Swal.fire({
            icon: "error",
            text: response.data.message || "Error deleting media",
          });
        }
      } catch (err) {
        Swal.fire({
          icon: "error",
          text: "Network error deleting media",
        });
      }
    }
  };

  const handleManualCapture = (selectedCameras) => {
    capturePhoto(selectedCameras);
  };

  const handleManualRecording = (selectedCameras) => {
    if (activeRecordings.size > 0) {
      stopRecording(Array.from(activeRecordings));
    } else {
      startRecording(selectedCameras);
    }
  };
  useEffect(() => {
    if (loading) {
      message.warning("Loading...");
    } else {
      message.destroy();
    }
  }, [loading]);
  return (
    <>
      <div className={styles.section} style={{ marginBottom: "120px" }}>
        <div className={styles.row}>
          <div className={styles.col}>
            <>
              <OrderLineItems
              productVerified={productVerified}
                orderData={currentOrder}
                setCurrentOrder={setCurrentOrder}
                onSelectionChange={(selectedProducts) => {
                  setSelectedProducts(selectedProducts);
                }}
                scannedBarcode={scannedBarcode}
              />
            </>
          </div>
          <div className={styles.col}>
            <Card
              className={cn(styles.card)}
              title="Orders"
              classTitle={cn("title-purple", styles.title)}
              classCardHead={cn(styles.head)}
              head={
                <>
                  <Form
                    className={styles.form}
                    value={searchInput}
                    setValue={setSearchInput}
                    placeholder={
                      canSearchNewOrder
                        ? "Scan Order Number"
                        : " SKU ,Camera Mode"
                    }
                    type="text"
                    name="search"
                    icon="search"
                    autoFocus={true}
                    onSubmit={handleSearch}
                  />
                  {activeRecordings.size > 0 && (
                    <Image
                      className="h-[25px] w-[60px]"
                      srcDark={Record}
                      src={Record}
                      alt="Record"
                    />
                  )}
                  <Dropdown
                    className={styles.locationDropdown}
                    value={selectedLocation}
                    setValue={handleLocationChange}
                    options={locations.map((loc) => loc.label)}
                  />
                </>
              }
            >
              <div className="flex flex-wrap gap-[15px]">
                {cameras.map((camera, index) => (
                  <div key={camera.id}>
                    <p className="mb-[15px]">{camera.name}</p>
                    {camera.deviceId ? (
                      <>
                        <video
                          ref={(el) => (videoRefs.current[index] = el)}
                          width="275"
                          height="273"
                          autoPlay
                          muted
                          style={{ flex: "1 1 120px", borderRadius: "12px" }}
                        />
                        <div className="flex gap-2 justify-center mt-2">
                          {currentOrder && (
                            <>
                              <button
                                onClick={() => capturePhoto([camera.deviceId])}
                                className={cn("button-stroke ", styles.button)}
                              >
                                <Icon name="photo" size="24" fill="red" />
                                Photo
                              </button>
                              {activeRecordings.has(camera.deviceId) ? (
                                <button
                                  onClick={() =>
                                    stopRecording([camera.deviceId])
                                  }
                                  className={cn(
                                    "button-stroke-recording ",
                                    styles.button
                                  )}
                                >
                                  <Icon name="recording" size="24" fill="red" />{" "}
                                  Recording
                                </button>
                              ) : (
                                <button
                                  onClick={() =>
                                    startRecording([camera.deviceId])
                                  }
                                  className={cn(
                                    "button-stroke mr-[10px]",
                                    styles.button
                                  )}
                                >
                                  <Icon
                                    name="video_icon"
                                    size="24"
                                    fill="red"
                                  />
                                  Video
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <Image
                        className={styles.pic}
                        src={CameraImage}
                        srcDark={CameraImage}
                        alt="Core"
                      />
                    )}
                  </div>
                ))}
              </div>

              {currentOrder && (
                <div className="mt-[50px]">
                  <Product
                    initialOrderMedia={currentOrder.OrderMedia}
                    orderNumber={currentOrder.order_number}
                    handleRemoveAll={handleRemoveAll}
                    handleDelete={handleDelete}
                    mediaUploaded={mediaUploaded}
                    setMediaUploaded={setMediaUploaded}
                  />
                </div>
              )}
            </Card>
          </div>
        </div>
        {currentOrder && (
          <div className="fixed bottom-7 left-30 right-5 ">
            <Panel
              cameras={cameras}
              handleCapture={capturePhoto}
              handleRecording={handleManualRecording}
              activeRecordings={activeRecordings}
              markAsPacked={markAsPacked}
              currentOrder={currentOrder}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ScanOrderIndex;
