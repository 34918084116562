import React, { useEffect, useState } from "react";
import styles from "./OrderDetailsIndex.module.sass";
import styless from "./Index.module.sass";
import cn from "classnames";
import Details from "./Product/Details/index";
import Comments from "./Product/Comments";
import Panel from "../OrderDetails/Product/Panel";
import { GET_ORDERDETAILS } from "../../../API/Orders";
import { Card, message } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import useDarkMode from "use-dark-mode";
import {
  DELETE_ALL_ORDER_MEDIA,
  DELETE_SINGELORDER_MEDIA,
} from "../../../API/Scanner";
import Swal from "sweetalert2";
import Tags from "./Product/Tags";
import Icon from "../../../components/Icon";
const OrderDettailsIndex = () => {
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [orderDetails, setOrderDetails] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { id } = useParams();
  const orderNumber = id;
  useEffect(() => {
    const getOrderDetails = async () => {
      if (id) {
        try {
          const data = await GET_ORDERDETAILS(id);
          const orderData = data.data;
          setOrderDetails(orderData);
          message.success("Order details fetched successfully");
        } catch (error) {
          message.error(`Error fetching order details: ${error.message}`);
        }
      } else {
        console.error("Order ID is undefined");
      }
    };

    getOrderDetails();
  }, [id]);
  const handleRemoveAll = async () => {
    const confirmRemoveAll = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This action will remove all media associated with this order. This cannot be undone.",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove all media!",
    });

    if (confirmRemoveAll.isConfirmed) {
      try {
        const response = await DELETE_ALL_ORDER_MEDIA(orderDetails.id);

        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            text: "All media removed successfully.",
          });
          setOrderDetails((prevData) => ({
            ...prevData,
            video_count: 0,
            image_count: 0,
            OrderMedia: [],
          }));
        } else {
          Swal.fire({
            icon: "error",
            text: response.data.message || "Error removing media",
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
  };
  const handleDelete = async (mediaId) => {
    const confirmDelete = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This action cannot be undone.",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmDelete.isConfirmed) {
      try {
        const response = await DELETE_SINGELORDER_MEDIA(mediaId);

        if (response.status === 200) {
          setOrderDetails((prevMedia) => {
            if (Array.isArray(prevMedia)) {
              return prevMedia.filter((item) => item.id !== mediaId);
            } else {
              console.error("prevMedia is not an array:", prevMedia);
              return prevMedia;
            }
          });

          Swal.fire({
            icon: "success",
            text: "Media deleted successfully.",
          });
        } else {
          Swal.fire({
            icon: "error",
            text: response.data.message || "Error deleting media",
          });
        }
      } catch (err) {
        Swal.fire({
          icon: "error",
          text: "Network error deleting media",
        });
      }
    }
  };

  const handleClose = () => {
    setActiveIndex(0);
    setVisible(false);
  };
  const darkMode = useDarkMode(false);
  const navigation = [
    "Order Details",
    "Billing Details",
    "Customer Address",
    "Product Gallery",
  ];
  const navigate = useNavigate();

  const handleOk = async () => {
    try {
      navigate("/packingslip", {
        state: {
          ids: [orderDetails?.id],
        },
      });
    } catch (error) {
      console.error("Error navigating:", error);
    }
  };
  return (
    <>
      <div className={styless.row}>
        <div className={styless.col}>
          <Card className={styless.card} classTitle="title-red">
            <div className="flex  justify-between">
              <div className="flex w-[80%]">
                <div className="mr-[1%]">
                  <Link
                    className={cn(
                      "button-stroke button-small",
                      styles.backbutton
                    )}
                    to="/orders"
                  >
                    <Icon name="arrow-left" size="24" />
                    <span>Back</span>
                  </Link>
                </div>
                <div className={styles.nav}>
                  {navigation.map((navItem, index) => (
                    <button
                      className={cn(styles.button, {
                        [styles.active]: index === activeTabIndex,
                      })}
                      onClick={() => setActiveTabIndex(index)}
                      key={index}
                    >
                      {navItem}
                    </button>
                  ))}
                </div>
              </div>
              <div className="flex justify-end">             
                <button
                  className={cn("button-stroke button-small")}
                  onClick={handleOk}
                >
                  Print Packing Slip
                </button>
              </div>
            </div>
            <Details
              className={styles.details}
              setValue={setVisible}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              orderDetails={orderDetails}
              orderNumber={orderNumber}
              handleRemoveAllMedia={handleRemoveAll}
              handleDelete={handleDelete}
              activeTabIndex={activeTabIndex}
            />
          </Card>
        </div>
        <div className={styless.col}>
          <Card className={styles.details} classTitle="title-purple">
            <Tags className={styles.comments} orderDetails={orderDetails} />
          </Card>

          <div>
            <Card
              className={cn(styles.details, "mt-[10px]")}
              classTitle="title-purple"
            >
              <Comments
                className={styles.comments}
                orderDetails={orderDetails}
              />
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDettailsIndex;
