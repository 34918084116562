import custom_axios from "../axios";
export const get_tags_barcode = async (url) => {
  try {
    const data = {
      url: url,
    };
    const response = await custom_axios.get(
      `/store-settings/tags/barcode`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch tag barcode:", error);
    throw error;
  }
};

export const get_one_tags_barcode = async (id) => {
  try {
    const response = await custom_axios.get(
      `/store-settings/tags/barcode/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch tag barcode:", error);
    throw error;
  }
};

export const create_tags_barcode = async (data) => {
  try {
    const formData = {
      tag_name: data.tag_name,
      barcode: data.barcode,
    };
    const response = await custom_axios.post(
      `/store-settings/tags/barcode`,
      formData
    );
    return response;
  } catch (error) {
    console.error("Failed to create tags config:", error);
    throw error;
  }
};
export const update_tags_barcode = async (data) => {
  try {
    const formData = {
      tag_name: data.tag_name,
      barcode: data.barcode,
    };
    const response = await custom_axios.patch(
      `/store-settings/tags/barcode/${data.id}`,
      formData
    );
    return response;
  } catch (error) {
    console.error("Failed to update tags config:", error);
    throw error;
  }
};
