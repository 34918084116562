import React, { useState } from "react";
import {
  ConfigProvider,
  Divider,
  Select,
  Space,
  Radio,
  DatePicker,
  Button,
} from "antd";
import cn from "classnames";
import styles from "../Released.module.sass";
import Modal from "../../../../components/Modal";
import Checkbox from "../../../../components/Checkbox";
import useDarkMode from "use-dark-mode";
import { GET_FETCH_TAG_SUGGESTION } from "../../../../API/Orders";
import Dropdown from "../../../../components/Dropdown";
import dayjs from "dayjs";
function Filter({ filterConfigs, activeFilter, handleFilterClick, appliedFilters, setAppliedFilters,handleReturnOrdersClick,isReturnActive}) {
  const { RangePicker } = DatePicker;
  const [visibleModal, setVisibleModal] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [tempFilterValue, setTempFilterValue] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const darkMode = useDarkMode(false);
  const dateFormat = "YYYY-MM-DD";
  const dateFormatOne = "YYYY-MM-DD";
  const [displayedFilters, setDisplayedFilters] = useState(
    filterConfigs.slice(0, 5)
  );
  const [dropdownFilters, setDropdownFilters] = useState(
    filterConfigs.slice(5)
  );
  const [selectedDropdownFilter, setSelectedDropdownFilter] =
    useState("More Filters");
    const handleReturnOrdersButtonClick = () => {
      handleReturnOrdersClick();
    };
    
  const handleOk = () => {
    if (!currentFilter) return;
    setAppliedFilters(prev => ({
      ...prev,
      [currentFilter.title]: tempFilterValue
    }));
    if (currentFilter.isDateFilter) {
      currentFilter.onDateFilterChange(
        tempFilterValue.selectedDateValue,
        tempFilterValue.fromDate,
        tempFilterValue.toDate
      );
    } else {
      currentFilter.onChange(tempFilterValue);
    }
    setVisibleModal(false);
  };

  const handleCancel = () => {
    if (currentFilter) {
      setTempFilterValue(appliedFilters[currentFilter.title] || getInitialFilterValue(currentFilter));
    }
    setVisibleModal(false);
  };
  const getInitialFilterValue = (filter) => {
    if (filter.isDateFilter) {
      return {
        selectedDateValue: filter.valueFilterByDate || 0,
        fromDate: filter.valueFilterByFromDate || "",
        toDate: filter.valueFilterByToDate || ""
      };
    } else if (filter.isTagFilter || filter.type === "select") {
      return [];
    }
    return "";
  };
  const handleTempChange = (newValueOrEvent) => {
    let value;
    if (Array.isArray(newValueOrEvent)) {
      value = newValueOrEvent;
    } else if (
      newValueOrEvent &&
      typeof newValueOrEvent === "object" &&
      newValueOrEvent.hasOwnProperty("value")
    ) {
      value = newValueOrEvent.value;
    } else {
      value = newValueOrEvent;
    }
    setTempFilterValue(value);
  };
  const handleDropdownChange = (selectedValue) => {
    const selectedFilter = dropdownFilters.find(
      (filter) => filter.title === selectedValue
    );
    if (selectedFilter) {
      const newDisplayedFilters = [
        ...displayedFilters.slice(0, 4),
        selectedFilter,
      ];
      const removedFilter = displayedFilters[4];
      setDisplayedFilters(newDisplayedFilters);
      setDropdownFilters([
        ...dropdownFilters.filter((f) => f !== selectedFilter),
        removedFilter,
      ]);
      setSelectedDropdownFilter("More Filters");
      showModal(selectedFilter);
    }
  };
  const setDateRange = (start, end) => {
    const fromDate = new Date(start);
    fromDate.setUTCHours(0, 0, 0, 0);

    const toDate = new Date(end);
    toDate.setUTCHours(23, 59, 59, 999);

    setTempFilterValue((prev) => ({
      ...prev,
      fromDate: fromDate.toISOString(),
      toDate: toDate.toISOString(),
    }));
  };
  const onDateChange = (e) => {
    const selectedValue = e.target.value;
    setTempFilterValue((prev) => ({
      ...prev,
      selectedDateValue: selectedValue,
      fromDate: "",
      toDate: "",
    }));
  };
  
  const onChangeCustomDate = (dates, dateStrings) => {
    const [start, end] = dateStrings;
    setTempFilterValue((prev) => ({
      ...prev,
      selectedDateValue: 6,
      fromDate: start || prev.fromDate,
      toDate: end || prev.toDate,
    }));
  };

  const dateOptions = [
    { value: 1, label: "Today" },
    { value: 2, label: "Last 7 days" },
    { value: 3, label: "Last 30 days" },
    { value: 4, label: "Last 90 days" },
    { value: 5, label: "Last 365 days" },
    { value: 6, label: "Custom" },
  ];
  const showModal = (filter) => {
    setVisibleModal(true);
    setCurrentFilter(filter); 
    const initialValue = appliedFilters[filter.title] || getInitialFilterValue(filter);
    setTempFilterValue(initialValue);    
    handleFilterClick(filter.title);
  };
  const isFilterActive = (filterTitle) => {
    const appliedValue = appliedFilters[filterTitle];
    if (!appliedValue) return false;
    
    if (Array.isArray(appliedValue)) {
      return appliedValue.length > 0;
    } else if (typeof appliedValue === 'object') {
      return appliedValue.selectedDateValue !== 0 || appliedValue.fromDate || appliedValue.toDate;
    }
    return !!appliedValue;
  };
  const handleTaggedWithSearch = async (value) => {
    try {
      const response = await GET_FETCH_TAG_SUGGESTION(value);
      const suggestions = response.data.map((item) => item.tag_name.trim());
      setSuggestions(suggestions);
    } catch (error) {
      console.error("Failed to fetch suggestions for taggedWith:", error);
    }
  };

  const handleNotTaggedWithSearch = async (value) => {
    try {
      const response = await GET_FETCH_TAG_SUGGESTION(value);
      const suggestions = response.data.map((item) => item.tag_name.trim());
      setSuggestions(suggestions);
    } catch (error) {
      console.error("Failed to fetch suggestions for notTaggedWith:", error);
    }
  };

  const themeConfig = {
    token: {
      colorPrimary: "#1890ff",
      colorTextBase: darkMode.value ? "#ffffff" : "#000000",
      colorBgContainer: darkMode.value ? "#1f1f1f" : "#ffffff",
      colorBgElevated: darkMode.value ? "#2f2f2f" : "#ffffff",
      colorText: darkMode.value ? "#ffffff" : "#000000",
      buttonBoxShadow: darkMode.value ? "none" : "0 2px 0 rgb(230, 247, 255)",
    },
  };

  const renderFilterContent = () => {
    if (!currentFilter) return null;
    if (currentFilter.isDateFilter) {
      return (
        <Radio.Group
          onChange={onDateChange}
          value={tempFilterValue.selectedDateValue}
        >
          <Space direction="vertical">
            {dateOptions.map((option) => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
            {tempFilterValue.selectedDateValue === 6 && (
              <Space direction="vertical" size={12}>
                <RangePicker
                  format={dateFormat}
                  onChange={onChangeCustomDate}
                  value={[
                    tempFilterValue.fromDate
                      ? dayjs(tempFilterValue.fromDate)
                      : null,
                    tempFilterValue.toDate
                      ? dayjs(tempFilterValue.toDate)
                      : null,
                  ]}
                  allowEmpty={[true, true]}
                />
              </Space>
            )}
            <Button
              type="primary"
              onClick={() =>
                setTempFilterValue({
                  selectedDateValue: 0,
                  fromDate: "",
                  toDate: "",
                })
              }
            >
              Clear
            </Button>
          </Space>
        </Radio.Group>
      );
    } else if (currentFilter.type === "radio") {
      return (
        <Space direction="vertical">
          {currentFilter.values.map((option) => (
            <Checkbox
              key={option.value}
              value={tempFilterValue.includes(option.value)}
              content={option.label}
              onChange={(e) => {
                const checked = e.target.checked;
                const newValue = checked
                  ? [...tempFilterValue, option.value]
                  : tempFilterValue.filter((val) => val !== option.value);
                handleTempChange(newValue);
              }}
            />
          ))}
        </Space>
      );
    } else if (currentFilter.isTagFilter) {
      return (
        <Select
          mode="multiple"
          allowClear
          style={{ width: "100%" }}
          placeholder={`Search and select ${currentFilter.title.toLowerCase()}`}
          value={tempFilterValue}
          onSearch={
            currentFilter.isTaggedWith
              ? handleTaggedWithSearch
              : handleNotTaggedWithSearch
          }
          onChange={handleTempChange}
          options={(suggestions && suggestions.length > 0
            ? suggestions
            : currentFilter.suggestions || []
          ).map((tag) => ({ value: tag, label: tag }))}
          filterOption={false}
          notFoundContent={null}
          dropdownStyle={{
            maxHeight: 200,
            overflowY: "auto",
          }}
          onPopupScroll={(e) => {}}
          dropdownRender={(menu) => <div>{menu}</div>}
        />
      );
    } else {
      return (
        <Select
          mode="tags"
          style={{ width: "100%" }}
          placeholder="Please Select"
          onChange={handleTempChange}
          value={tempFilterValue}
          options={currentFilter.values}
        />
      );
    }
  };

  return (
    <>
      <div className={cn("flex flex-wrap")}>
      <button
          className={cn(styles.buttonhead, {
            [styles.active]: isReturnActive,
          })}
          onClick={handleReturnOrdersButtonClick}
        >
          Returned Orders
        </button>
        {displayedFilters.map((filter) => (
          <button
            className={cn(styles.buttonhead, {
              [styles.active]: isFilterActive(filter.title),
            })}
            key={filter.title}
            onClick={() => showModal(filter)}
          >
            {filter.title}
          </button>
        ))}
        <div className={cn("ml-[5px]")}>
          <Dropdown
            classDropdownHead={styles.dropdownHead}
            value={selectedDropdownFilter}
            setValue={handleDropdownChange}
            options={[
              "More Filters",
              ...dropdownFilters.map((filter) => filter.title),
            ]}
          />
        </div>
      </div>
      <ConfigProvider theme={themeConfig}>
        <Modal
          outerClassName={styles.outer}
          visible={visibleModal}
          onClose={() => setVisibleModal(false)}
        >
          <div className={styles.details}>
            <div className={cn("title-purple", styles.title)}>
              {currentFilter?.title}
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <Divider />
                {renderFilterContent()}
                <div className={cn(styles.btns, "mt-5 mr-[10px]")}>
                  <button
                    className={cn("button-stroke mr-3", styles.button)}
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    className={cn("button", styles.button)}
                    onClick={handleOk}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </ConfigProvider>
    </>
  );
}

export default Filter;