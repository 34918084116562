import React, { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { usePermissions } from "../../context/Permissions/PermissionsContext";
import AccessControl from "../../HOC/AccessControl";
import cn from "classnames";
import styles from "./Settings.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Dropdown from "../../components/Dropdown";
import ProfileInformation from "./ProfileInformation";
import Login from "./Login";
import Notifications from "./Notifications";
import Payment from "./Payment";

import { useMenu } from "../../context/Roles/MenuContext";

import View from "./Roles/View/View";
import BarcodeView from "./Barcode/View/View";
import CreateBarcode from "./Barcode/Create/index";
import UpdateBarcode from "./Barcode/Update/index";
import CreateCamera from "./Camera/CreateCamera/Create";
import CameraView from "./Camera/View/View";
import UpdateCamera from "./Camera/UpdateCamera/Update";
import Panel from "./Panel";
import CreateRole from "./Roles/CreateRole/Create";
import CreatePermission from "./Roles/CreatePermissions/Create";
import UpdateRole from "./Roles/UpdateRole/Update";
import UpdatePickList from "./Picklist/View/View";
import Tags from "./Tags/TagsSettings/View/Tags";
import ViewCategory from "./Tags/TagsCategory/View/Index"
import TagBarcodeView from "./TagBarcode/View/View";
import CreateTagBarcode from "./TagBarcode//Create/index";
import UpdateTagBarcode from "./TagBarcode//Update/index";
const Settings = () => {
  const location = useLocation();
  const tab = location.state?.setting_tab ? location.state?.setting_tab : 0;

  const filterNavigation = (navigation, permissions) => {
    if (permissions === "All") {
      return navigation; // If user has all permissions, return all navigation items
    }

    return navigation.filter((navItem) =>
      permissions.some(
        (perm) =>
          perm.module_permission.module_id === navItem.module &&
          perm.module_permission.code === navItem.permissions
      )
    );
  };
  const navigation = [
    {
      title: "Roles",
      action: () =>
        scrollToRoles.current.scrollIntoView({ behavior: "smooth" }),
      module: "BjQPiy",
      permissions: "OZnKi4",
    },
    {
      title: "Camera",
      action: () =>
        scrollToCamera.current.scrollIntoView({ behavior: "smooth" }),
      module: "gsZHRE",
      permissions: "nnlrNJ",
    },
    {
      title: "Barcode",
      action: () =>
        scrollToBarcode.current.scrollIntoView({ behavior: "smooth" }),
      module: "FvrVqN",
      permissions: "nJLdKh",
    },
    {
      title: "Pick List",
      action: () =>
        scrollToPickList.current.scrollIntoView({ behavior: "smooth" }),
      module: "k5WoWh",
      permissions: "kI6pTK",
    },
    {
      title: "Store Settings",
      action: () =>
        scrollToProfile.current.scrollIntoView({ behavior: "smooth" }),
      module: "18q9ha",
      permissions: "4Yy3A1",
    },
    {
      title: "Tags",
      action: () => scrollToTags.current.scrollIntoView({ behavior: "smooth" }),
      module: "VBaqtS",
      permissions: "HRkhhh",
    },
    {
      title: "Action Barcode for Tags",
      action: () => scrollToTags.current.scrollIntoView({ behavior: "smooth" }),
      module: "2sxE9L",
      permissions: "OFUpm7",
    },
  ];
  const { permissions, status } = usePermissions();
  const filteredNavigation = filterNavigation(navigation, permissions);

  // const navigation = [
  //   // {
  //   //   title: "Basics",
  //   //   action: () =>
  //   //     scrollToProfile.current.scrollIntoView({ behavior: "smooth" }),
  //   // },
  //   // {
  //   //   title: "Account",
  //   //   action: () =>
  //   //     scrollToLogin.current.scrollIntoView({ behavior: "smooth" }),
  //   // },
  //   // {
  //   //   title: "Notifications",
  //   //   action: () =>
  //   //     scrollToNotifications.current.scrollIntoView({ behavior: "smooth" }),
  //   // },
  //   // {
  //   //   title: "Payment",
  //   //   action: () =>
  //   //     scrollToPayment.current.scrollIntoView({ behavior: "smooth" }),
  //   // },
  //   {
  //     title: "Roles",
  //     action: () =>
  //       scrollToRoles.current.scrollIntoView({ behavior: "smooth" }),
  //     module: "BjQPiy",
  //     permissions: "OZnKi4",
  //   },
  //   {
  //     title: "Camera",
  //     action: () =>
  //       scrollToCamera.current.scrollIntoView({ behavior: "smooth" }),
  //     module: "gsZHRE",
  //     permissions: "nnlrNJ",
  //   },
  //   {
  //     title: "Barcode",
  //     action: () =>
  //       scrollToBarcode.current.scrollIntoView({ behavior: "smooth" }),
  //     module: "FvrVqN",
  //     permissions: "nJLdKh",
  //   },
  // ];
  const options = [];
  filteredNavigation.map((x) => options.push(x.title));
  const [activeTab, setActiveTab] = useState(options[tab]);

  const [activeIndex, setActiveIndex] = useState(tab);
  const scrollToProfile = useRef(null);
  const scrollToLogin = useRef(null);
  const scrollToNotifications = useRef(null);
  const scrollToPayment = useRef(null);
  const scrollToRoles = useRef(null);
  const scrollToCamera = useRef(null);
  const scrollToBarcode = useRef(null);
  const scrollToPickList = useRef(null);
  const scrollToTags = useRef(null);
  const scrollToTagsBarcode = useRef(null);
  const { isMenuVisible, setIsMenuVisible, subActiveTab, setSubActiveTab } =
    useMenu(); // Use the context
  const handleClick = (x, index) => {
    setActiveIndex(index);
    setActiveTab(options[index]);
    x.action();
    setSubActiveTab(1);
    // setIsMenuVisible(true);
  };
  // const renderActiveModule = () => {
  //   switch (activeTab) {
  //     case "Notifications":
  //       return <Notifications />;
  //     case "Roles":
  //       return <View />;
  //     // case "Basics":
  //     //   return <ProfileInformation />;
  //     // case "Account":
  //     //   return <Login />;
  //     // case "Payment":
  //     //   return <Payment />;
  //     default:
  //       return null;
  //   }
  // };
  return (
    <>
      <div className={styles.settings}>
        {isMenuVisible && (
          <div className={styles.menu}>
            {filteredNavigation.map((x, index) => (
              <AccessControl
                moduleCode={x.module}
                requiredPrivilege={x.permissions}
              >
                <button
                  className={cn(styles.button, {
                    [styles.active]: activeIndex === index,
                  })}
                  key={index}
                  onClick={() => handleClick(x, index)}
                >
                  {x.title}
                </button>
              </AccessControl>
            ))}
          </div>
        )}
        <div className={styles.wrapper}>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={activeTab}
            setValue={setActiveTab}
            options={options}
          />
          <div className={styles.list}>
            <AccessControl moduleCode="BjQPiy" requiredPrivilege="OZnKi4">
              {" "}
              <div
                className={cn({
                  [styles.active]: activeTab === "Roles",
                })}
              >
                <div className={styles.anchor} ref={scrollToRoles}></div>
                {activeTab === "Roles" && subActiveTab == 1 && <View />}
              </div>
              <div
                className={cn({
                  [styles.active]: activeTab === "Roles",
                })}
              >
                <div className={styles.anchor} ref={scrollToRoles}></div>

                {activeTab === "Roles" && subActiveTab == 2 && (
                  <CreateRole setIsMenuVisible={setIsMenuVisible} />
                )}

                {activeTab === "Roles" && subActiveTab == 3 && (
                  <CreatePermission setIsMenuVisible={setIsMenuVisible} />
                )}

                {activeTab === "Roles" && subActiveTab == 4 && (
                  <UpdateRole setIsMenuVisible={setIsMenuVisible} />
                )}
              </div>
            </AccessControl>
            <div
              className={cn({
                [styles.active]: activeTab === "Barcode",
              })}
            >
              <div className={styles.anchor} ref={scrollToBarcode}></div>
              {activeTab === "Barcode" && subActiveTab == 1 && <BarcodeView />}
              {activeTab === "Barcode" && subActiveTab == 2 && (
                <CreateBarcode />
              )}
              {activeTab === "Barcode" && subActiveTab == 3 && (
                <UpdateBarcode />
              )}
            </div>

            <div
              className={cn({
                [styles.active]: activeTab === "Camera",
              })}
            >
              <div className={styles.anchor} ref={scrollToCamera}></div>
              {activeTab === "Camera" && subActiveTab == 1 && <CameraView />}
              {activeTab === "Camera" && subActiveTab == 2 && <CreateCamera />}
              {activeTab === "Camera" && subActiveTab == 3 && <UpdateCamera />}
            </div>
            <div
              className={cn({
                [styles.active]: activeTab === "Pick List",
              })}
            >
              <div className={styles.anchor} ref={scrollToPickList}></div>
              {activeTab === "Pick List" && subActiveTab == 1 && (
                <UpdatePickList />
              )}
            </div>
            <div
              className={cn({
                [styles.active]: activeTab === "Store Settings",
              })}
            >
              <div className={styles.anchor} ref={scrollToProfile}></div>
              {activeTab === "Store Settings" && subActiveTab == 1 && (
                <ProfileInformation />
              )}
            </div>

            <div
              className={cn({
                [styles.active]: activeTab === "Tags",
              })}
            >
              <div className={styles.anchor} ref={scrollToTags}></div>
              {activeTab === "Tags" && subActiveTab == 1 && <Tags />}
              {activeTab === "Tags" && subActiveTab == 2 && <ViewCategory />}
            </div>

            <div
              className={cn({
                [styles.active]: activeTab === "Action Barcode for Tags",
              })}
            >
              <div className={styles.anchor} ref={scrollToTagsBarcode}></div>
              {activeTab === "Action Barcode for Tags" && subActiveTab == 1 && <TagBarcodeView />}
              {activeTab === "Action Barcode for Tags" && subActiveTab == 2 && <CreateTagBarcode />}
              {activeTab === "Action Barcode for Tags" && subActiveTab == 3 && <UpdateTagBarcode />}
            </div>
            {/* <div
              className={cn({
                [styles.active]: activeTab === "Notifications",
              })}
            >
              <div className={styles.anchor} ref={scrollToNotifications}></div>
              {activeTab === "Notifications" && <Notifications />}
            </div>

            <div
              className={cn(styles.item, {
                [styles.active]: activeTab === "Account",
              })}
            >
              <div className={styles.anchor} ref={scrollToLogin}></div>
              {activeTab === "Account" && <Login />}
            </div>
            <div
              className={cn(styles.item, {
                [styles.active]: activeTab === "Payment",
              })}
            >
              <div className={styles.anchor} ref={scrollToPayment}></div>
              {activeTab === "Payment" && <Payment />}
            </div> */}
          </div>
          {/* <button className={cn("button", styles.button)}>Save</button> */}

          {/* <div className={styles.list}>
            <div
              className={cn(styles.item, {
                [styles.active]: activeTab === options[0],
              })}
            >
              <div className={styles.anchor} ref={scrollToProfile}></div>
              <ProfileInformation />
            </div>
            <div
              className={cn(styles.item, {
                [styles.active]: activeTab === options[1],
              })}
            >
              <div className={styles.anchor} ref={scrollToLogin}></div>
              <Login />
            </div>
            <div
              className={cn(styles.item, {
                [styles.active]: activeTab === options[2],
              })}
            >
              <div className={styles.anchor} ref={scrollToNotifications}></div>
              <Notifications />
            </div>
            <div
              className={cn(styles.item, {
                [styles.active]: activeTab === options[3],
              })}
            >
              <div className={styles.anchor} ref={scrollToPayment}></div>
              <Payment />
            </div>

            <div
              className={cn(styles.item, {
                [styles.active]: activeTab === options[4],
              })}
            >
              <div className={styles.anchor} ref={scrollToPayment}></div>
              <Roles />
            </div>
          </div> */}
          {/* <button className={cn("button", styles.button)}>Save</button> */}
        </div>
      </div>
      <TooltipGlodal />
      <Panel
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setSubActiveTab={setSubActiveTab}
        subActiveTab={subActiveTab}
      ></Panel>
    </>
  );
};

export default Settings;
