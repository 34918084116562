import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Inventory.module.sass";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Checkbox from "../../../components/Checkbox";
const Inventory = ({ className }) => {
  const [content, setContent] = useState();

  return (
    <Card
      className={cn(styles.card, className)}
      title="Inventory"
      classTitle="title-green"
      head={
        <Link
          className={cn("button-stroke button-small", styles.button)}
          to="/products/dashboard"
        >
          <Icon name="arrow-left" size="24" />
          <span>Back</span>
        </Link>
      }
    >
      <div className={styles.description}>
        <Checkbox className={styles.checkbox} content={"Track Quantity"} />
      </div>
      <div className={styles.description}>
        <p>Quantity</p>

        <div className={styles.group}>
          <p className={styles.field}>My Custom Location</p>

          <TextInput
            className={styles.field}
            name="value2"
            type="text"
            placeholder="Value"
            required
          />

          <p className={styles.field}>Shop Location</p>
          <TextInput
            className={styles.field}
            name="value4"
            type="text"
            placeholder="Value"
            required
          />
        </div>
        <div className={styles.description}>
          <Checkbox className={styles.checkbox} content={"Continue selling when out of stock"} />
        </div>
      </div>
      <div className={styles.group}>
        <TextInput
          className={styles.field}
          label="SKU"
          name="value1"
          type="text"
          placeholder="Value"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
        />
        <TextInput
          className={styles.field}
          label="Barcode"
          name="value2"
          type="text"
          placeholder="Value"
          required
        />
      </div>
    </Card>
  );
};

export default Inventory;
