import custom_axios from "../axios";

export const CREATE_ORDER = async (order_number) => {
  try {
    const response = await custom_axios.post(`/order/create`, {
      order_number: order_number,
    });

    return response.data;
  } catch (error) {
    console.error("Failed to create order:", error);
    throw error;
  }
};
