import React from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";

const Panel = ({ isSubmitting, onCancel }) => {
  return (
    <div className={cn("panel", styles.panel)}>
      <div className={styles.info}></div>
      <div className={styles.btns}>
        <button
          type="button"
          className={cn("button-stroke", styles.button)}
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className={cn("button", styles.button)}
          disabled={isSubmitting}
        >
         Save
        </button>
      </div>
    </div>
  );
};

export default Panel;
