import React from "react";
import cn from "classnames";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import styles from "./CreateOrder.module.sass";
import Card from "../../components/Card";
import Icon from "../../components/Icon";
import TextInput from "../../components/TextInput";
import Panel from "./Panel";
import { CREATE_ORDER } from "../../API/CreateOrder";
import CustomToast from "../../components/CustomToast";

const validationSchema = Yup.object().shape({
  order_number: Yup.string()
    .required("Order number is required")
    .max(100, "Maximum 100 characters allowed"),
});

const initialValues = {
  order_number: "",
};
const CreateOrder = ({ className }) => {
  const showToast = CustomToast();
  const handleSave = async (values, { setSubmitting, resetForm }) => {
    try {
      const data = await CREATE_ORDER(values.order_number);
      showToast("Order created successfully!", "success");
      resetForm();
    } catch (error) {
      showToast("Failed to create order: " + error.message, "error");
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSave}
      >
        {({
          isSubmitting,
          setFieldValue,
          values,
          errors,
          touched,
          status,
          resetForm,
          setStatus,
        }) => (
          <Form>
            <Card
              className={cn(styles.card, className)}
              title="Order"
              classTitle="title-blue"
              head={
                <Link
                  className={cn("button-stroke button-small", styles.button)}
                  to="/orders"
                >
                  <Icon name="arrow-left" size="24" />
                  <span>Back</span>
                </Link>
              }
            >
              <div className={styles.description}>
                <div className={styles.field_container}>
                  <Field name="order_number">
                    {({ field }) => (
                      <TextInput
                        {...field}
                        className={styles.field}
                        label="Order Number"
                        name="order_number"
                        type="text"
                        tooltip="Maximum 100 characters. No HTML or emoji allowed"
                        error={touched.order_number && errors.order_number}
                      />
                    )}
                  </Field>
                  {status && status.message && (
                    <div
                      className={cn(styles.message, {
                        [styles.success]: status.success,
                        [styles.error]: !status.success,
                      })}
                    >
                      {status.message}
                    </div>
                  )}
                </div>
              </div>
            </Card>
            <div className="fixed bottom-7 left-30 right-5 ">
              <Panel
                isSubmitting={isSubmitting}
                onCancel={() => {
                  resetForm();
                  setStatus(null);
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateOrder;
