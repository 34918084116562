import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./View.module.sass";
import Item from "../../Item";

import Table from "../Table";
import { get_tags_barcode } from "../../../../API/TagBarcode";
import { useMenu } from "../../../../context/Roles/MenuContext";
import { Formik, Form } from "formik";
import { useFormikContext } from "../../context/SettingsContext";

const View = () => {
  const { formikRef } = useFormikContext();
  const [visible, setVisible] = useState(false);

  const { setSubActiveTab } = useMenu();
  const [barcode, setBarcode] = useState([]);
  const handleCreateBarcode = () => {
    setSubActiveTab(2);
  };

  const fetchAllBarcode = async () => {
    const res = await get_tags_barcode();

    setBarcode(res.data);
  };
  const initialValues = {
    name: "",
  };
  useEffect(() => {
    fetchAllBarcode();
  }, []);
  return (
    <Item className={cn(styles.card)} title="Action Barcode for Tags" classTitle="title-purple">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleCreateBarcode}
        innerRef={formikRef}
      >
        {({ isSubmitting, setFieldValue, values, errors, touched }) => (
          <Form>{/* Empty form to trigger formik ref */}</Form>
        )}
      </Formik>
      <div className={styles.list}>
        <Table
          data={barcode}
          className={styles.table}
          activeTable={visible}
          setActiveTable={setVisible}
        />
      </div>
    </Item>
  );
};

export default View;
